.text-bg-primary{
    background-color: #d2ddec !important;
    color: #003448 !important;
}

.btn-close{
    width: 2px;
    margin-right: 4px;
    vertical-align: text-top;
}

.reset{
    cursor: pointer;
}