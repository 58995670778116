@import '../../styles/variables.scss';

.main-box {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.pointer {
  cursor: pointer
}

.userSelect {
  user-select: none;
}

p {
  margin: 0;
}

.investments {
  padding-top: 110px;
  background: #F5F7FA;

  .btn-outline-theme {
    --bs-btn-color: #003448;
    --bs-btn-border-color: #003448;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #003448;
    --bs-btn-hover-border-color: #003448;
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #003448;
    --bs-btn-active-border-color: #003448;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #003448;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #003448;
    --bs-gradient: none;
  }

  .btn-link-theme {
    --bs-btn-font-weight: 800;
    --bs-btn-color: #003448;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #003448;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: #003448;
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: 0 0 0 #000;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    text-decoration: underline;
  }

  .headline {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 46px;
    padding-bottom: 32px;

    p {
      color: #003448;
      // font-family: Avenir;
      font-family: 'Avenir LT Std Heavy' !important;
      font-size: 26px !important;
      font-weight: 350;
      line-height: 31px;
      /* 129.167% */
      letter-spacing: 1px;
    }
  }

  .investment-text {
    z-index: 1;
    position: relative;
  }

  .investment-header {
    background: linear-gradient(90deg, #003448 15.9%, rgba(0, 52, 72, 0) 92.01%), url('../../../public/Investments-header.jpg') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }

  .statement-box {
    margin-left: 80px;
    margin-right: 80px;

    border-radius: 5px;
    border: 1px solid rgba(219, 223, 227, 0.30);
    //background: #003448 !important;
    box-shadow: 0px 3px 12px 5px rgba(217, 226, 233, 0.50);
    //padding: 40px 36px;
    padding: 20px 20px 20px 20px !important;
    text-align: center;
    height: 100% !important;

    p,
    span {
      color: #FFF !important;
      // font-family: Avenir;
      font-family: 'Avenir LT Std Medium' !important;
      font-size: 20px;
      padding: 10px;
    }

    .name {
      p {
        color: #003448;
        // font-family: Avenir;
        font-family: 'Avenir LT Std Medium' !important;
        font-size: 20px;

        font-weight: 350;
        line-height: 27px;
        text-transform: uppercase;
      }
    }

    .id {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    span {
      color: #5582A0;
      font-feature-settings: 'clig' off, 'liga' off;

      /* Body */
      font-family: Avenir LT Std;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      /* 150% */
    }

    .updatabtn {
      // margin-top: 24px;
      // margin-bottom: 24px;
    }

    .investments-button {
      button {
        margin-bottom: 5px;
      }

      .download-btn {
        width: 300px;
        height: 40px;

        background: #FFC81C;
        border: 1px solid #003448;
        border-radius: 5px;
        font-family: 'Avenir LT Std Medium';
        color: #003448;
      }
    }
  }

  .tabs {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;
    margin-bottom: 40px;

    .tabsName {
      width: 50%;
      display: flex;
      justify-content: center;
      padding: 11px 0;
      position: relative;

      p {
        color: #9B9B9B;
        text-align: center;
        // font-family: Avenir;
        font-family: 'Avenir LT Std Medium' !important;
        font-size: 20px !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 24px;
        /* 133.333% */
        text-transform: uppercase;
      }
    }

    .checked {
      p {
        color: #003448 !important;
      }
    }

    .checked::before {
      content: '';
      width: 100%;
      height: 5px !important;
      background: $yellow !important;
      position: absolute;
      bottom: 0;
    }
  }

  .tabsContent {
    margin-left: 80px;
    margin-right: 80px;

    .total-box {
      padding: 40px 36px;
      background: #E4EEF2;

      .time {
        color: #003448;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: 'Avenir LT Std Medium' !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        margin-bottom: 12px;
      }

      .total {
        border-radius: 5px;
        padding-top: 32px;
        padding-bottom: 32px;
        margin-bottom: 12px;



        p {
          color: #FFF;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;

          /* Title */
          font-family: 'Avenir LT Std Medium' !important;
          font-size: 24px !important;
          font-style: normal;
          font-weight: 350;
          line-height: 31px;
          /* 129.167% */
          letter-spacing: 1px;
        }

        .amount-box {
          .amount {
            color: #FFF;
            text-align: center;
            white-space: nowrap;
            /* Input */
            font-family: 'Avenir LT Std Medium' !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;


            span {
              margin-left: 5px;
              color: #FFF;
              font-family: 'Avenir LT Std Medium' !important;
              font-size: 24px !important;
              font-style: normal;
              font-weight: 800;
              line-height: normal;
            }
          }

          .amount-left {
            position: absolute;
            right: 24px;
            top: -3px;
          }

          .amount-right {
            position: absolute;
            left: 24px;
            top: -3px;
          }

          .vertical {
            width: 2px;
            height: 18px;
            background: #FFF;
            margin-top: 10px;
            position: relative;
          }
        }
      }

      .total:last-child {
        margin-bottom: 0;
      }

      .total-bg1 {
        background: #003448;
      }

      .total-bg2 {

        background: #5582A0;
      }

      .total-bg3 {
        background: #636466 !important;
      }
    }

    .pending-box {
      padding-bottom: 10px;

      th {
        padding-top: 24px;
        padding-bottom: 24px;
        font-family: 'Avenir LT Std Heavy' !important;
        background-color: #5582A0;
        color: #FFF;
      }

      th:last-child {
        padding-right: 32px;
      }

      th:first-child {

        padding-left: 32px;
      }

      td {
        padding-top: 24px;
        padding-bottom: 24px;
      }

      td:last-child {
        padding-right: 32px;
      }

      td:first-child {

        padding-left: 32px;
      }

      .Pending {
        margin-top: 40px;

        p {
          color: #003448;
          font-feature-settings: 'clig' off, 'liga' off;

          /* Sub-title */
          font-family: 'Avenir LT Std Heavy' !important;
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
        }
      }

      .table-box {
        margin: 24px 0;
        border-radius: 5px;
        padding-bottom: 30px;

        .name {
          color: #003448;
          font-family: 'Avenir LT Std Medium' !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 600 !important;
          line-height: 24px;
          /* 150% */
        }

        .place {
          color: #003448;
          font-family: 'Avenir LT Std Medium' !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 24px;
          /* 150% */
        }

        .amount {
          color: #003448;
          font-family: 'Avenir LT Std Medium' !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          /* 150% */
        }

        .date {
          color: #003448;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: 'Avenir LT Std Medium' !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          /* 150% */
        }

        .status {
          color: #003448;
          font-family: 'Avenir LT Std Medium' !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          /* 150% */
        }
      }

      .list-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-family: 'Avenir LT Std Medium' !important;

        .name-box {
          // width: 100%;
          // display: flex;
          // align-items: center;
          // background: #5582A0;
          // padding: 20px 32px;
        }

        .name {
          padding: 0 !important;
          color: #FFF;
          font-feature-settings: 'clig' off, 'liga' off;
          background: transparent !important;
          /* Sub-title */
          // font-family: Avenir LT Std;
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
          font-family: 'Avenir LT Std Heavy' !important;
        }

        .place {
          color: #003448;
          font-family: 'Avenir LT Std Medium' !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px;
          margin-top: 8px;
        }

        .amount {
          color: #003448;
          font-feature-settings: 'clig' off, 'liga' off;

          /* Session_title */
          font-family: 'Avenir LT Std Medium' !important;
          font-size: 18px;
          font-style: normal;
          font-weight: 750;
          line-height: 20px;
          /* 111.111% */
        }

        .date {
          color: #003448;
          font-feature-settings: 'clig' off, 'liga' off;

          /* Session_title */
          font-family: 'Avenir LT Std Medium' !important;
          font-size: 18px;
          font-style: normal;
          font-weight: 750;
          line-height: 20px;
          /* 111.111% */
        }

        .status {
          color: #003448;
          font-feature-settings: 'clig' off, 'liga' off;

          /* Session_title */
          font-family: 'Avenir LT Std Medium' !important;
          font-size: 18px;
          font-style: normal;
          font-weight: 750;
          line-height: 20px;
          /* 111.111% */
        }

        .list {
          width: 622px;
          height: auto !important;
          border-radius: 5px;
          border: 1px solid rgba(219, 223, 227, 0.30);
          background: #FFF;
          box-shadow: 0px 3px 12px 5px rgba(217, 226, 233, 0.50);
          margin-top: 24px;
          align-self: start;

          .list-label {
            margin-top: 0 !important;
            width: 100%;
            background-color: #5582A0;
            padding: 20px 50px;
            color: #fff;

            .name {
              font-size: 24px !important;
              margin-bottom: 10px;
              font-family: 'Avenir LT Std Heavy' !important;
            }

            .location {
              font-size: 14px !important;

            }
          }

          .bg-blue {
            padding: 4px 32px !important;
            background-color: #003448 !important;
          }

          .list-padding {
            padding: 20px !important;

            .list-title {
              color: #4F7A96;
              font-size: 14px;
              font-family: 'Avenir LT Std Book' !important;
            }
          }

          .divider {
            height: 1px;
            width: 100%;
            margin-top: 24px;
            margin-bottom: 24px;
            background-color: #4F7A96;
          }

          .list-content {
            max-width: 234px;
            padding-left: 20px;
            padding-top: 15px;

            span {
              white-space: nowrap;
            }

            .date {
              white-space: nowrap;
            }
          }

          .list-top {
            max-width: 234px;
            padding-left: 20px;
            padding-top: 10px;
          }
        }


        .list-loan {
          width: 622px;
          height: 300px;
          border-radius: 5px;
          border: 1px solid rgba(219, 223, 227, 0.30);
          background: #FFF;
          box-shadow: 0px 3px 12px 5px rgba(217, 226, 233, 0.50);
          margin-top: 24px;

          .list-label {
            margin-top: 0 !important;
            width: 100%;
            background-color: #5582A0;
            padding: 20px 50px;
            color: #fff;

            .name {
              font-size: 24px !important;
              margin-bottom: 10px;
              font-family: 'Avenir LT Std Heavy' !important;
            }

            .location {
              font-size: 14px !important;

            }
          }

          .list-padding {
            padding: 20px !important;

            .list-title {
              color: #4F7A96;
              font-size: 14px;
              font-family: 'Avenir LT Std Book' !important;
            }
          }

          .divider {
            height: 1px;
            width: 100%;
            margin-top: 24px;
            margin-bottom: 24px;
            background-color: #4F7A96;
          }

          .list-content {
            max-width: 234px;
            padding-left: 20px;
            padding-top: 15px;
          }

          .list-top {
            max-width: 234px;
            padding-left: 20px;
            padding-top: 10px;
          }
        }

        .list:last-child {
          margin-bottom: 24px;
        }

      }
    }


  }
}

.left-border {
  border-left: 3px solid $yellow;
  padding: 5px 5px 5px 20px;
}

.investments {
  .back-button {
    align-items: center;
    margin-bottom: 30px;

    .back-text {
      font-size: 16px !important;
    }
  }
}

.mobile-box {
  display: none;
}

@media only screen and (max-width: 768px) {
  .investments {
    .headline {
      padding: 20px 20px 24px !important;
    }

    .statement-box {
      margin-left: 20px !important;
      margin-right: 20px !important;
      padding: 20px !important;

      // .updatabtn {
      //   button {
      //     width: 100%;
      //     text-align: left;
      //   }
      // }

      // .statement {
      //   button {
      //     width: 100%;
      //     text-align: left;
      //   }
      // }

      // height: 260px !important;

      span,
      p {
        font-size: 14px !important;
      }

      .investment-text {
        text-align: left;
      }


      .investment-header {
        display: none;
      }
    }

    .tabsContent {
      .total-box {
        padding: 20px !important;

        .total {
          .amount-box {
            .amount {
              span {
                font-size: 18px !important;
              }
            }
          }
        }
      }

      .pending-box {
        .table-box {
          display: none;
        }
      }

      .list-box {
        display: none !important;
      }
    }

    .togge-table {
      display: none !important;
    }
  }

  .mobile-box {
    display: block;
    margin-top: 25px;


    .moblie-list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      padding: 20px;
      background-color: #fff;
      margin-bottom: 16px;
      border: 1px solid rgba(217, 217, 217, 0.3);


      .mobile-item {
        width: calc((100% - 20px) / 2);
      }

      .moblie-list-title {
        font-size: 14px;
        color: #4F7A96;
      }

      .moblie-list-name {
        font-size: 16px;
        color: #003448;
        font-weight: 800;
      }

      .w-full {
        width: 100%;
      }
    }
  }

  .mobile-hiden {
    display: none;
  }
}