.foot {
  background: var(--Dark-Blue, #003448);

  width: 100%;

  .main-box {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }

  .contact-box {
    padding: 40px 80px;

    .contact {
      width: 400px;
      color: #fff;

      //font-family: Avenir LT Std;
      font-family: 'Avenir LT Std Light';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
    }

    .connect {
      width: 400px;
      color: #fff;

      //font-family: Avenir LT Std;
      font-family: 'Avenir LT Std Light';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      .instagram-box {
        margin-top: 14px;

        .instagram {
          font-size: 30px;
          font-weight: bold;
          width: 30px;
          line-height: 30px;
          margin-right: 33px;
          cursor: pointer;
          margin-left: 25px;
        }
      }
    }

    .locations {
      cursor: pointer;
      width: 400px;
      color: #fff;

      //font-family: Avenir LT Std;
      font-family: 'Avenir LT Std Light';
      font-size: 18px;

      font-weight: 400;

      .list-before {
        color: #ffffff;
        //font-family: Avenir LT Std;
        font-family: 'Avenir LT Std Light';
        font-size: 12px;
        font-weight: 400;
      }

      .list-before::before {
        content: '|';
        color: #31e8ff;
        float: right;
        //font-family: Avenir LT Std;
        font-family: 'Avenir LT Std Light';
        font-size: 12px;
        font-weight: 750;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .disclaimer {
      margin-top: 60px;

      .llc {
        color: #fff;

        /* Input */
        //font-family: Avenir LT Std;
        font-family: 'Avenir LT Std Light';
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 12px;
      }

      .details {
        color: #fff;
        //font-family: Avenir LT Std;
        font-family: 'Avenir LT Std Light';
        font-size: 12px;
        font-weight: 400;
      }

      .tersmofuse {
        width: 400px;
        color: #fff;
        /* text-align: center; */
        //font-family: Avenir LT Std;
        font-family: 'Avenir LT Std Light';
        font-size: 14px;
        font-weight: 400;
        margin-top: 20px;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .foot {
    .contact-box {
      padding: 40px 20px;

      .contact-us {
        flex-direction: column;
      }

      .locations {
        width: 100%;
      }

      .contact {
        width: 100%;
        margin-bottom: 24px;
      }

      .connect {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }

}