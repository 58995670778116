.form {
  position: absolute;
  top: 45%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 100%;
  height: 100%;
  padding: 36px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);

  button {
    width: 100%;
  }

  p {
    color: rgb(204, 204, 204);
    text-align: center;
    margin-top: 16px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }
}
.table-border{
  // border-color: inherit;
  border-style: solid;
  border-width: 1px;
}
.title{
  color: #003448;
  padding-top: 35px;
  padding-bottom: 35px;
  font-size: 24px;
}
.background{
  background-color: #f5f7fa;

}
.float-right{
  float:right
}
.float-left{
  float:left
}
.table-body{
  padding-top: 35px;
  padding-bottom: 35px;
}
.searh-row{
  padding-top: 35px;
  padding-bottom: 35px;
}
.table{
  td{
    color: #003448;
  }
  tr{
    height: 72px ;
  }
  th{
    color: #b4b4b4;
  }
}
.fly-mid {
	display: inline-block;
	height: 10px;
	width: 1px;
	vertical-align: middle;
	background-color: #e2e2e2;
}
.searchBtn {
  margin-left: 20px;
  margin-right: 20px;
  height: 42px;
  width: 100px;
  font-family: Avenir LT Std;
  font-size: 16px;
  font-weight: 700;
}
.btn-outline-theme {
  --bs-btn-color: #003448;
  --bs-btn-border-color: #003448;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #003448;
  --bs-btn-hover-border-color: #003448;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #003448;
  --bs-btn-active-border-color: #003448;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #003448;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #003448;
  --bs-gradient: none;
}

.nav-tabs .nav-link {
    margin-bottom: 0px;
    border: 0px solid transparent;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #0d3448;
  background-color: #f5f7fa;
  border-color: #0d3448;
  border-bottom: 1px solid #0d3448;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-border-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}



