#stepper-exit-offer-returns-div {
    .exit-offer-returns-content {
        padding-top: 50px;

        table {
            width: 100% !important;
        }
    }

    @media only screen and (max-width: 768px) {
        table,
        thead,
        tbody {
            padding: 5px !important;
            width: 100% !important;
            min-width: 100% !important;
            padding: 0 !important;
        }
       
        tr, td, th {
            padding: 0 5px 20px 5px !important;
            font-size: 14px !important;
            line-height: 20px !important;
        }        
     }
}