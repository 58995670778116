.exit-projects {
    .offer-sub-text {
        font-size: 14px;
    }

    .offer-text {
        font-weight: 500;
        font-size: 18px;
        font-family: 'Avenir LT Std Heavy';
    }

    .exit-vote-button {
        background-color: #FEC81E;
        color: #2D2958;
        border: none;
        padding: 5px 25px;
        font-weight: bold;
        width: 150px;
        vertical-align: middle;
        font-size: 18px;
    }

    td {
        width: 300px;
    }

    .check-exit-vote-button {
        position: relative;
        left: -3px;
        top: -1px;
    }

    @media only screen and (max-width: 768px) {
        th:last-child {
            display: none;
        }

        td:first-child {
            border-bottom: 1px solid #ddd !important;
        }

        thead tr { 
            position: absolute !important;
            top: -9999px !important;
            left: -9999px !important;
        }
    }
}