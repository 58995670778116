.land-and-loan-investments {
    font-family: 'Avenir LT Std Book';

    table {
        text-align: center;

        td {
            font-size: 16px !important;
        }
    }



    td:first-child {
        text-align: left;
    }

    th:first-child {
        text-align: left;
    }

    @media only screen and (max-width: 768px) {
        th, td {
            text-align: left;
        }
    }
}