#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}
.header {
  position: relative;
  background-color: #fff;
  color: #000;
}
.slider {
  width: 280px !important;
  max-width: 280px !important;
  min-width: 280px !important;
  overflow-y: auto;
}
.ant-layout-sider-collapsed.slider {
  width: 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
}
.logout-menu{
  float: right;
}
