.landing {
  .line {
    width: 40%;
    height: 3px;
    background-color: #003448;
  }

  .page-content {
    padding-top: 120px;
    padding-bottom: 120px;
    background: #f5f7fa;
  }

  .headline {
    color: #003448;
    text-align: center;
    font-family: Berthold Akzidenz Grotesk;
    font-size: 36px;
    font-weight: 400;
    // line-height: 24px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    // white-space: nowrap;
    margin: 0 6px;
  }

  .main-box {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: #0d3448;
  }

  .content-desc {
    padding-bottom: 30px;
  }

  .content-button {
    padding-bottom: 30px;

    button {
      height: 50px;
    }
  }

  .content-forget {
    font-size: 13px;
    color: #5582a0;
    text-decoration: underline;
    cursor: pointer;
  }
}

.headline-box {
  padding-top: 80px;
  padding-bottom: 80px;

  .headline {
    color: #003448;
    text-align: center;
    font-family: Berthold Akzidenz Grotesk;
    font-size: 36px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    white-space: nowrap;
    margin: 0 6px;
  }

  .line {
    width: 100%;
    height: 3px;
    background-color: #003448;
  }
}

.landing-modal-box {
  width: 700px !important;

  div {
    padding: 0 !important;
  }

  .ant-modal-close-x {
    color: #fff;
    font-size: 26px;
  }
}

.landing-modal {
  width: 100%;

  .image-box {
    img {
      width: 100%;
      border-radius: 8px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .landing-modal-box {
    width: 70% !important;
  }
}

@media only screen and (max-width: 768px) {
  .landing {
    .page-content {
      padding-top: 150px;
      background: #fff;
    }

    .line {
      width: 10%;
      height: 2px;
    }

    .headline {
      font-size: 24px;
      line-height: 26px;
      padding: 20px;
      white-space: normal;
      padding-top: 10px;
    }

    .main-box {
      padding: 0 20px;

      button {
        width: 100%;
      }
    }
  }

  .landing-modal-box {
    width: 90% !important;
  }

}