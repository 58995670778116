.home-exit-banner {
    padding-top: 130px;
}

.home {
    padding: 10px 80px 80px 80px;
    font-family: 'Avenir LT Std Book';
    color: #003448;

    @media only screen and (max-width: 768px) {
        padding: 10px;

        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        td {
            width: 100% !important;
            font-size: 14px !important;
        }

        table {
            padding: 0;
        }
    }

    table {
        border: solid #ddd 1px;
        vertical-align: middle;
        text-align: left;
        width: 100%;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    td,
    th {
        font-size: 16px;
        padding: 10px 25px;
        font-weight: 500;
        font-family: 'Avenir LT Std Medium';
    }

    th {
        background-color: #5582A1;
        color: #FAF9F6;
        border-top: none;
        font-family: 'Avenir LT Std Heavy';
    }

    tbody tr {
        border-bottom: 1px solid #ddd;
    }

    tfoot tr {
        background-color: #F2F7FA;
    }

    tfoot td {
        font-weight: bold;
    }

    .home-section {
        padding: 10px 20px 15px 20px;
    }

    .home-title {
        font-weight: bold !important;
        font-size: 18px;
        font-family: 'Avenir LT Std Heavy';
    }

    .portfolio-view {
        padding: 5px;

        h2 {
            font-weight: bold !important;
        }
    }
}