//定义主题颜色
$primary: #ff8257;
$black: #000000;
$grey-900: #1f2432;
$grey-600: #919191;
$grey-500: #777777;
$grey-400: #626262;
$grey-300: #b4b4b4;
$grey-100: #efefef;

$border: #dddddd;
$white: #ffffff;
$bg: #f8f7fe;
$disable-bg: #efefef;
$green-font: #408917;
$green-bg: #dcffc8;
$green-icon: #7ad800;
$red-font: #ed1111;
$red-bg: #ffe0e0;
$purple-font: #5533a2;
$purple-bg: #e8e4ff;
$yellow-font: #e77d00;
$yellow-bg: #ffefcc;
$yellow: #FFC81C;

//common
$container-width: 1140px;