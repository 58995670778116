.exit-voting {
    background-color: #FFFFFF;
    padding-top: 140px;
    display: flex;
    justify-content: center;

    button {
        height: 50px;
        border-radius: 23px;
        margin-bottom: 0;
        margin-top: 0;
        color: #fff;
        background-color: #003448;
        border-style: none;
        padding-right: 30px;
        padding-left: 30px;
        margin-bottom: 20px;
        float: right;
    }

    .main-box {
        max-width: 1446px;
        margin-left: auto;
        margin-right: auto;

    }

    .pointer {
        cursor: pointer
    }

    .statement-box {
        margin-left: 80px;
        margin-right: 80px;
        margin-bottom: 10px;

        border-radius: 5px;
        border: 1px solid rgba(219, 223, 227, 0.30);
        background: #FFF;
        box-shadow: 0px 3px 12px 5px rgba(217, 226, 233, 0.50);
        padding: 40px 36px;
    }

    .sigCanvas {
        border: 1px solid #ddd;
    }
}