.main-box {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.pointer {
  cursor: pointer
}

.userSelect {
  user-select: none;
}

p {
  margin: 0;
}

.ApplyBtn {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 29px;
  padding-right: 29px;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  //background-color: #0d3448;
  color: white;

  /* button */
  // font-family: Avenir LT Std;
  // font-size: 16px;
  // font-weight: 700;
  // line-height: 19px;
  margin-left: 16px;

  height: 42px;
  width: 200px;
  background: #FFC81C;
  border-radius: 5px;
  border: none;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  font-family: 'Avenir LT Std Medium';
  color: #0d3448;
}

.ApplyBtn:hover {
  background: #FFC81C;
  font-family: 'Avenir LT Std Heavy';
}

.profile {
  .profile-exit-banner {}


  @media only screen and (max-width: 768px) {
    .profile-header {
      display: none;
    }

    .profile-wrapper {
      margin: 30px 0 20px 0 !important;
      width: 100% !important;
    }

    .profile-title {
      padding-top: 40px;
      margin-top: 150px;
    }

  }

  .profile-header {
    height: 116px;
    background: linear-gradient(90deg, #003448 15.9%, rgba(0, 52, 72, 0) 92.01%), url('../../../public/profile-header.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    top: -77px;
    left: 454px;
  }

  .profile-wrapper {
    width: 1280px;
    height: 116px;
    background: #003448;
    margin: 30px 80px 30px 80px;

    div {
      p {
        color: #fff !important;
        font-family: 'Avenir LT Std Book';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
      }
    }
  }

  .tax-container {
    .tax-title {
      font-family: 'Avenir LT Std Book';
      font-size: 18px;
      font-weight: 750;
      line-height: 22px;
      color: #003448;
      padding: 10px 10px 10px 0;
    }

    span {
      font-size: 18px;
      font-family: 'Avenir LT Std Book';
      position: relative;
      top: 5px;
    }

    select {
      height: 40px;
      width: 300px;
      border: 1px solid #003448;
      border-radius: 5px;
      font-family: 'Avenir LT Std Medium';
      text-align: center;
      vertical-align: middle;
    }

    .us-tax-engagement:disabled {
      background-color: #E9ECEF;
    }

    .tax-disclaimer {
      svg {
        float: left;
      }

      .svg-exclamation {
        position: relative;
        left: -10px;
        top: 4px;
      }

      p {
        display: inline;
        font-family: 'Avenir LT Std Light';
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 19px;
        margin-left: 5px;
        color: #003448;
      }

      .tax-disclaimer-text {
        padding-left: 25px;

        p {
          display: block;
        }
      }
    }

    .tax-table {
      td {
        font-size: 18px;
        padding: 0 10px 0 10px;
        font-family: 'Avenir LT Std Book';
        height: 80px !important;

        input,
        select {
          height: 40px;
          width: 300px;
          border: 1px solid #003448;
          border-radius: 5px;
          font-family: 'Avenir LT Std Medium';
          text-align: center;
          vertical-align: middle;
        }
      }

      .ant-form-item {
        padding-top: 20px;
      }
    }
  }

  padding-top: 120px;
  padding-bottom: 120px;
  background: #F5F7FA;

  .btn-outline-theme {
    --bs-btn-color: #003448;
    --bs-btn-border-color: #003448;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #003448;
    --bs-btn-hover-border-color: #003448;
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #003448;
    --bs-btn-active-border-color: #003448;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #003448;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #003448;
    --bs-gradient: none;
  }

  .btn-link-theme {
    --bs-btn-font-weight: 800;
    --bs-btn-color: #003448;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #003448;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: #003448;
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: 0 0 0 #000;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    text-decoration: underline;
  }

  .headline {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 46px;
    padding-bottom: 32px;

    p {
      color: #003448;
      // font-family: Avenir;
      font-family: 'Avenir LT Std Book' !important;
      font-size: 26px;
      font-weight: 350;
      line-height: 31px;
      /* 129.167% */
      letter-spacing: 1px;
    }
  }

  .statement-box {
    margin-left: 80px;
    margin-right: 80px;
    //margin-bottom: 80px;
    // border-radius: 5px;
    // border: 1px solid rgba(219, 223, 227, 0.30);
    // background: #FFF;
    // box-shadow: 0px 3px 12px 5px rgba(217, 226, 233, 0.50);
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0212249);
    border-radius: 5px;
    padding: 36px;
    display: flex;
    flex-wrap: wrap;

    .list {
      margin-top: 32px;

      span {
        color: #003448;
        // font-family: Avenir;
        font-family: 'Avenir LT Std Book' !important;
        font-size: 16px;
        font-weight: 300;
        line-height: 22px;
      }

      p {
        color: #003448;
        font-feature-settings: 'clig' off, 'liga' off;

        /* button */
        // font-family: Avenir LT Std;
        font-family: 'Avenir LT Std Heavy' !important;
        font-size: 18px;
        font-weight: 700;
        line-height: 19px;
        margin-top: 8px;

      }
    }

    .list:nth-child(1) {
      margin: 0;
    }

    .list:nth-child(2) {
      margin: 0;
    }
  }

  .tabs {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;
    margin-bottom: 40px;

    .tabsName {
      width: 50%;
      display: flex;
      justify-content: center;
      padding: 8px 0;
      position: relative;

      p {
        color: #9B9B9B;
        text-align: center;
        font-family: Avenir;
        font-size: 18px;
        font-style: normal;
        font-weight: 350;
        line-height: 24px;
        /* 133.333% */
        text-transform: uppercase;
      }
    }

    .checked {
      p {
        color: #003448 !important;
      }
    }

    .checked::before {
      content: '';
      width: 100%;
      height: 3px;
      background: #003448;
      position: absolute;
      bottom: 0;
    }
  }

  .tabsContent {
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 40px;
    // border-radius: 5px;
    // border: 1px solid rgba(219, 223, 227, 0.30);
    // background: #FFF;
    // box-shadow: 0px 3px 12px 5px rgba(217, 226, 233, 0.50);
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0212249);
    border-radius: 5px;
    padding: 20px 32px;

    .custom-switch {
      transform: scale(1.4);
    }

    .ant-switch-checked .ant-switch-inner {
      background-color: #5582A0;
      border-color: #5582A0;
    }

    .password-section {
      @media only screen and (max-width: 768px) {
        .custom-input-pwd {
          width: 300px !important;
        }
      }

      .custom-input-pwd {
        width: 370px;
        border: 1px solid #003448;
        border-radius: 5px;
        font-family: 'Avenir LT Std Medium';
      }
    }

    .information {
      .title {
        color: #003448;
        font-feature-settings: 'clig' off, 'liga' off;

        /* Session_title */
        // font-family: Avenir LT Std;
        font-family: 'Avenir LT Std Heavy';
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: 20px;
        /* 111.111% */
        margin-bottom: 24px;
      }

      .list-box {
        display: flex;
        flex-wrap: wrap;

        .list {
          // margin-top: 16px;
          font-size: 18px;
          font-family: 'Avenir LT Std Book';
          font-weight: 300 !important;


        }



        .list:nth-child(1) {
          margin: 0;
          padding-right: 34px;
        }

        .list:nth-child(2) {
          margin: 0;
          padding-left: 34px;
        }

        .list:nth-child(3) {

          padding-right: 34px;
        }

        .list:nth-child(4) {

          padding-left: 34px;
        }

        .list:nth-child(5) {

          padding-right: 34px;
        }

        .list:nth-child(6) {

          padding-left: 34px;
        }

        .input-box {
          width: 400px;
          padding-top: 20px;

          .select-phone {}

          .form-phone {
            margin-left: 8px;
          }

          input.form-control {
            border: 1px solid #003448 !important
          }
        }
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #D9D9D9;
      margin-top: 32px;
      margin-bottom: 32px;
    }

    .mailing-address {
      .list-box {
        .list {
          .ant-form-item {
            width: 425px;
          }
        }
      }
    }

    .address {
      .postal-dropdown {
        width: 370px;
        border: 1px solid #003448;
        border-radius: 5px;
        font-family: 'Avenir LT Std Medium';
      }

      .postal-dropdown-menu {
        max-height: 200px;
        overflow-y: scroll;
        width: 370px;
      }

      @media only screen and (max-width: 768px) {
        .custom-input {
          width: 300px !important;
        }

        .custom-select {
          width: 300px !important;
        }

        .postal-dropdown {
          width: 300px !important;
        }

        .postal-dropdown-menu {
          width: 300px;
        }
      }

      .custom-input {
        width: 370px;
        border: 1px solid #003448;
        border-radius: 5px;
        font-family: 'Avenir LT Std Medium';
      }

      .custom-select {
        width: 370px;
      }

      .title {
        color: #003448;
        font-feature-settings: 'clig' off, 'liga' off;

        /* Session_title */
        // font-family: Avenir LT Std;
        // font-size: 18px;
        // font-style: normal;
        font-family: 'Avenir LT Std Heavy';
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        font-weight: 750;
        line-height: 20px;
        /* 111.111% */
      }

      .hint {
        margin-top: 32px;
        margin-bottom: 32px;

        p {
          color: var(--Dark-Blue, #003448);
          font-feature-settings: 'clig' off, 'liga' off;
          // font-family: Avenir;
          font-family: 'Avenir LT Std Light';
          font-size: 14px;
          font-style: normal;
          font-weight: 350;
          line-height: 19px;
          margin-left: 8px;

        }

        .svg-box {
          width: 20px;
          height: 20px;
          position: relative;

          .svg-circle {
            position: absolute;
            top: -3px;
            left: -3px;
          }

          .svg-exclamation {
            position: absolute;
            top: 2px;
            left: 6px;
          }
        }
      }

      .list-box {
        .list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 16px;

          p {
            color: #003448;
            // font-family: Avenir;
            font-family: 'Avenir LT Std Book';
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 22px;
          }

          // input {
          //   width: 300px;
          //   border: 1px solid #003448;
          //   border-radius: 5px;
          //   font-family: 'Avenir LT Std Medium';
          // }

          .ant-form-item {
            padding-top: 20px;
          }
        }

        .list:nth-child(1) {
          margin: 0;
        }


      }

    }

    .identification {
      .title {
        color: #003448;
        font-feature-settings: 'clig' off, 'liga' off;

        /* Session_title */
        // font-family: Avenir LT Std;
        // font-size: 18px;
        //font-style: normal;
        //font-weight: 750;
        font-family: 'Avenir LT Std Heavy';
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: 20px;
        /* 111.111% */
      }

      .list-box {
        .list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 16px;
          margin-bottom: 24px;

          p {
            color: #003448;
            font-family: Avenir;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 22px;
          }

          .check-box {
            display: flex;
            justify-content: space-between;
            width: 300px;

            .check {
              display: flex;
              align-items: center;

              p {
                color: var(--Dark-Blue, #003448);
                font-feature-settings: 'clig' off, 'liga' off;

                /* Question/ANS */
                font-family: Avenir LT Std;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                margin-left: 12px;
              }
            }
          }
        }



      }
    }

    .settings {
      .title {
        color: #003448;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: 'Avenir LT Std Heavy';
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: 20px;
      }

      .setting-content {
        font-size: 18px;
        font-family: 'Avenir LT Std Book';
        padding: 10px;
      }
    }

    .comm-pref {
      .title {
        color: #003448;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: 'Avenir LT Std Heavy';
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: 20px;
      }

      .comm-pref-content {
        font-size: 18px;
        font-family: 'Avenir LT Std Book';
        padding: 10px;
      }
    }
  }

  .pending-box {
    min-height: 72px;
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 28px;
    border-radius: 5px;
    border: 1px solid rgba(219, 223, 227, 0.30);
    background: #FFF;
    box-shadow: 0px 3px 12px 5px rgba(217, 226, 233, 0.50);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .left-icon {
      display: flex;
      align-items: center;
      min-height: 72px;
      padding: 36px;
      background-color: #E3401E;
    }

    .right-text {
      flex: 1;
      padding: 36px;
      font-size: 16px;
      font-weight: 700;
      color: #E3401E;
    }
  }
}

.select-box {
  display: flex;
  justify-content: space-between;

  .css-b62m3t-container {
    display: flex;
    width: 50%;
  }

  .css-349wcb-control {
    display: flex;
    flex: 1;
  }

  .css-qxu49p-control {
    display: flex;
    flex: 1;
  }
}

.profile {
  .profile-details {
    padding: 10px 10px 10px 0;
    padding-bottom: 25px;

    span {
      height: 22px;
      font-family: 'Avenir LT Std Book';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      color: #003448;
      padding: 5px;
    }

    p {
      height: 25px;

      font-family: 'Avenir LT Std Heavy';
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 25px;
      /* identical to box height */

      color: #003448;
      padding: 5px;
    }

    .error-message {
      padding-left: 15px;
      line-height: 22px;
      font-size: 14px;
      font-weight: 400 !important;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
    }

    input {
      border: 1px solid #003448;
    }

    .form-input {

      @media only screen and (max-width: 768px) {
        display: block;

        .phone-select {
          width: 110px !important;
        }

        .custom-select {
          width: 300px !important;
        }

        .custom-input {
          width: 300px !important;
        }

        .us-residency-select {
          width: 300px !important;
        }

      }

      display: flex;
      vertical-align: middle;
      // align-items: center;
      height: 40px;
      margin-top: 20px;

      span {
        padding: 10px 0 10px 0;
        width: 160px;
        text-align: left;
      }

      .form-input-phone {
        input {
          width: 180px !important;
        }
      }

      .phone-select {
        width: 180px;
      }

      .custom-select {
        width: 370px;
      }

      .custom-input {
        width: 370px;
      }

      .us-residency-select {
        height: 40px;
        width: 370px;
        border: 1px solid #003448;
        border-radius: 5px;
        font-family: 'Avenir LT Std Medium';
        text-align: left;
        vertical-align: middle;
        padding-left: 10px;
        padding-right: 20px;
      }
    }
  }

  .password-input {
    align-items: center !important;
    padding-top: 20px;
  }

  .change-btn {
    margin-top: 5px;
    right: 0.75rem;
  }
}

.disabled-btn {
  background-color: #0d3448 !important;
  opacity: 0.5 !important;

}

@media only screen and (max-width: 768px) {
  .profile {
    .headline {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 0;
    }

    .pending-box {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 32px;
      margin-top: 32px;

      .left-icon {
        padding: 10px;
      }
    }

    .statement-box {
      padding: 20px;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 32px;

      .list {
        width: 50%;
      }
    }

    .tabsContent {
      margin-left: 20px;
      margin-right: 20px;
      padding: 20px;

      .address {
        .list-box {
          .list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            p {
              margin-bottom: 12px;
            }

            .ant-form-item,
            input {
              width: 100%;
            }
          }

        }
      }

      .information {
        .list-box {
          .list {
            flex-direction: column;
            align-items: flex-start !important;
            padding-right: 0 !important;
            padding-left: 0 !important;
          }

          .input-box {
            width: 100%;
          }
        }

        .ant-form-item {
          width: 100%;
        }
      }

      #usState {
        min-width: 300px;
      }
    }

    .update-password {
      width: 100%;
      justify-content: flex-start !important;

      button {
        width: 100%;
        margin-right: 20px;
      }
    }

    .setting-content {
      justify-content: space-between;
    }
  }
}