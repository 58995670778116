.password-input {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;

    input {
        width: 100%;
        // padding: 0.8rem 0.8rem 0.8rem 0.8rem;

        &:focus {
            outline-style: none;
        }
    }

    .change-btn {
        position: absolute;
        right: 0;
        background-color: transparent;
        border: none;
    }

    .eye {
        font-size: 20px;
        color: #003448;
    }
}