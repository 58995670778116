.updata {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  border: 1px dashed;
  padding-top: 33px;
  padding-bottom: 33px;
  position: relative;

  .btn-outline-next {
    --bs-btn-color: #fff;
    --bs-btn-bg: #003448;
    --bs-btn-border-color: #003448;
    --bs-btn-hover-color: #003448;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #003448;
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: #003448;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #003448;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #fff;
    --bs-gradient: none;
  }

  .icon-box {
    padding: 9px 10px;
    border-radius: 12px;
    background: rgba(85, 130, 160, 0.23);
  }

  .file {
    color: #5582a0;
    text-align: center;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .filesBtn {
    padding: 5px 40px;
    text-align: center;
    font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 22px;
  }
  input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
}
