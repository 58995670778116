.loginTandC {
  margin-top: 120px;
  background: #f5f7fa;

  .btn-outline-next {
    --bs-btn-color: #fff;
    --bs-btn-bg: #003448;
    --bs-btn-border-color: #003448;
    --bs-btn-hover-color: #003448;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #003448;
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: #003448;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #003448;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #fff;
    --bs-gradient: none;
  }

  .btn-outline-link {
    --bs-btn-font-weight: 400;
    --bs-btn-color: #5582a0;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #5582a0;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: #5582a0;
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: 0 0 0 #000;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    text-decoration: underline;
  }

  .headline-box {
    padding-top: 80px;
    padding-bottom: 80px;

    .headline {
      color: #003448;
      text-align: center;
      font-family: Berthold Akzidenz Grotesk;
      font-size: 36px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      white-space: nowrap;
      margin: 0 6px;
      padding-left: 20%;
      padding-right: 20%;
    }

    .line {
      width: 100%;
      height: 3px;
      background-color: #003448;
    }
  }

  .content-box {
    margin-left: 80px;
    margin-right: 80px;
  }

  .box {
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 80px;

    .tible {
      font-family: Avenir;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: #5582a0;
    }

    .content {
      font-family: Avenir;
      font-size: 16px;
      font-weight: 300;
      line-height: 25px;
      letter-spacing: 0px;
      text-align: left;
      color: #003448;
    }

    .decimal {
      list-style: decimal;

      li {
        margin: 10px 0;
      }
    }
  }

  .Terms {
    display: flex;
    align-items: center;

    p {
      color: var(--Mid-Blue, #5582a0);
      font-family: Average;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      /* 128.571% */
      margin-left: 8px;
    }
  }

  .nextBtn {
    width: 300px;
    margin: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .loginTandC {
    .headline-box {
      padding-top: 20px;
      padding-bottom: 0px;

      .line {
        width: 10%;
        height: 2px;
      }

      .headline {
        font-size: 24px;
        line-height: 26px;
        padding: 20px;
        white-space: normal;
        padding-top: 10px;
      }
    }

    .content-box {
      margin: 0 20px;
    }

    .box {
      padding: 0px !important;
    }

    .btnBox {
      width: 100%;
    }

    .nextBtn {
      margin: 30px 0;
      width: 100%;
    }
  }
}