.exit-banner,
.vote-now-modal {

    .pending-box-missing {

        @media only screen and (max-width: 768px) {
            .right-text {
                font-size: 14px !important;
                padding: 10px !important;
            }

            .right-button {
                padding: 24px 5px 0 0 !important;
                .update-profile-button {
                    font-size: 12px !important;
                    width: 140px !important;
                }
            }
        }

        min-height: 72px;
        margin-top: 20px;
        margin-left: -13px;
        border-radius: 5px;
        border: 1px solid rgba(219, 223, 227, 0.30);
        background: #FFF;
        box-shadow: 0px 3px 12px 5px rgba(217, 226, 233, 0.50);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        .left-icon {
            display: flex;
            align-items: center;
            min-height: 72px;
            padding: 36px;
            background-color: #003448;
            color: #FFF;
        }

        .right-text {
            flex: 1;
            padding: 36px 24px 36px 24px;
            font-size: 24px;
            font-weight: 700;
            color: #cc0202;
            font-family: 'Avenir LT Std Medium';
        }

        .right-button {
            padding: 36px 36px 36px 36px;
            .update-profile-button {
                border: none;
                width: 300px;
                height: 46px;

                background: #FFC81C;
                border-radius: 12px;

                font-style: normal;
                font-weight: 750;
                font-size: 20px;
                line-height: 20px;
                color: #fff;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        padding: 0;

        .vote-col {


            .speech-bubble {
                height: 150px;
                width: 300px;
            }

            .for-exit-offer {
                position: relative;
                left: 35px !important;
                top: -160px !important;
                font-size: 16px !important;
                width: 300px !important;
            }

            .for-exit-offer-jp {
                font-size: 14px !important;
                left: 40px !important;
                line-height: 14px !important;
                width: 135px !important;
            }

            .v-svg {
                height: 35px;
                width: 32px;
                position: relative;
                left: -263px !important;
                top: -30px !important;
            }

            .o-svg {
                height: 35px;
                width: 32px;
                position: relative;
                left: -263px !important;
                top: -30px !important;
            }

            .t-svg {
                height: 35px;
                width: 32px;
                position: relative;
                left: 70px !important;
                top: -141px !important;
            }

            .e-svg {
                height: 35px;
                width: 32px;
                position: relative;
                left: 70px !important;
                top: -141px !important;
            }

            .check-svg {
                height: 35px;
                width: 32px;
                position: relative;
                left: 72px !important;
                top: -148px !important;
            }

            .e-mark-svg {
                height: 70px;
                left: 70px !important;
                top: -125px !important;
            }

        }

        .remaining-days-col {
            background-color: #fff;
            position: relative;
            top: -155px !important;
            height: 32px !important;
            padding: 0 !important;
            left: 50px;
            width: 200px;

            p {
                font-size: 13px;
                color: #003448;
                padding: 5px;
                line-height: 13px;
            }
        }

        .exit-banner-vote {
            position: relative;
            top: -285px;
            left: 385px;
            max-width: 90px;
            font-size: 14px !important;
        }

    }

    padding: 20px 85px 10px 105px;
    width: 100%;
    font-family: 'Avenir LT Std Book';

    .exit-banner-container {
        width: 100%;
        background-color: #003449;
        height: 150px;
        color: #fff;
    }

    .exit-banner-vote {
        border: none;
        width: 181px;
        height: 46px;

        background: #FFC81C;
        border-radius: 12px;

        font-style: normal;
        font-weight: 750;
        font-size: 20px;
        line-height: 20px;
        color: #003448;
    }

    .exclamation-mark {
        font-size: 120px;
        position: relative;
        top: -145px;
        left: 145px;
    }

    .exit-banner-vote-text {
        font-size: 50px;

    }

    .exit-banner-for-text {
        font-size: 24px;
    }

    .vote-row {
        padding: 15px;
    }

    .vote-col {
        .speech-bubble {
            position: relative;
            left: -12px;
            z-index: 0;
        }

        .v-svg {
            position: relative;
            left: 60px;
            right: 75.59%;
            top: -140px;
            bottom: 40.37%;

            background: #3DC3CB;
        }

        .o-svg {
            /* Vector */

            position: relative;
            left: 60px;
            top: -140px;

            background: #3DC3CB;

        }

        .check-svg {
            /* Vector */

            position: relative;
            left: 15px;
            top: -150px;
        }

        .t-svg {
            /* Vector */

            position: relative;
            left: 20px;
            top: -140px;
        }

        .e-svg {
            /* Vector */

            position: relative;
            left: 30px;
            top: -140px;
        }

        .e-mark-svg {
            /* Vector */
            position: relative;
            top: -117px;
            left: 50px;
        }

        .for-exit-offer {
            position: relative;
            left: 60px;
            top: -160px;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 34px;
            letter-spacing: 0.11em;

            color: #FFFFFF;


            /* Inside auto layout */
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;

        }

        .for-exit-offer-jp {
            position: relative;
            left: 60px;
            top: -155px;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 18px;
            letter-spacing: 0.11em;

            color: #FFFFFF;


            /* Inside auto layout */
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
            width: 220px;
        }
    }

    .remaining-days-col {
        // font-size: 36px;
        padding: 53px 40px 80px 0px;
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 43px;
        margin-left: -30px;
        font-family: 'Avenir LT Std Heavy';
    }

    .vote-button-col {
        padding: 50px 40px 80px 0px;
        margin-left: -80px;
    }
}

.vote-now-modal {
    .modal-style {
        height: 400px;
        width: 1146px;
        background-color: #003449;
    }

    .modal-bubble-speech {
        position: relative;
        top: -16px;
        left: -16px;
        border-top-left-radius: 8px;
    }

    .modal-v-svg {
        position: relative;
        top: -340px;
        left: 95px;
    }

    .modal-o-svg {
        position: relative;
        top: -340px;
        left: 100px;
    }

    .modal-check-svg {
        position: relative;
        top: -355px;
        left: 35px;
    }

    .modal-t-svg {
        position: relative;
        top: -340px;
        left: 50px;
    }

    .modal-e-svg {
        position: relative;
        top: -340px;
        left: 70px;
    }

    .modal-e-mark-svg {
        position: relative;
        top: -315px;
        left: 90px;
    }

    .modal-for-exit-offer {
        /* For Exit Offer */
        width: 316.06px;
        height: 47px;
        font-style: normal;
        font-weight: 700;
        font-size: 39.5073px;
        line-height: 47px;
        letter-spacing: 0.11em;

        color: #FFFFFF;


        /* Inside auto layout */
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;

        position: relative;
        top: -360px;
        left: 105px
    }

    .modal-for-exit-offer-jp {
        font-size: 28px;
        line-height: 30px;
    }

    .modal-remaining-days-col {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 8px 12px 8px 0px;
        gap: 2px;

        width: 488px;
        height: 52px;

        background: #FFFFFF;

        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;

        position: relative;
        left: -16px;
        top: -350px;

        p {
            font-style: normal;
            font-weight: 300;
            font-size: 24px;
            line-height: 36px;
            /* identical to box height */

            color: #003448;


            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;
        }
    }

    .modal-remaining-days-col-jp {
        left: -16px;
        top: -330px;
    }

    .modal-vote-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 110.06px;

        position: relative;
        width: 355px;
        height: 84px;
        left: 110px;
        top: 150px;

        background: #FFC81C;
        border-radius: 12px;

        font-style: normal;
        font-weight: 750;
        font-size: 20px;
        line-height: 20px;
        color: #003448;
        border: none;

    }

    .close-button-modal {
        padding: 10px;
        float: right;
    }


}