#stepper-cover-letter-div {
    .cover-letter-content {
        padding-top: 50px;
    }

    .faq-url {
        height: 50px;
        border-radius: 8px;
        color: #fff;
        background-color: #FFC81C;
        border-style: none;
        text-decoration: none;
        cursor: pointer;
        width: 300px;
        text-align: center;
        vertical-align: middle;
        padding: 8px;
        margin-top: 20px;
        position: relative;
        top: 40px;
        left: 980px;
        font-family: 'Avenir LT Std Heavy';
    }

    table {
        width: 100%;
    }

    @media only screen and (max-width: 768px) {
        table,
        thead,
        tbody {
            padding: 5px !important;
            width: 100% !important;
            min-width: 100% !important;
            padding: 0 !important;
        }
        
        tr, td, th {
            font-size: 14px !important;
            line-height: 20px !important;
        }    
        
        .faq-url {
            font-size: 10px !important;
            width: 115px !important;
            max-width: none !important;
            left: 225px !important;
            top: 20px !important;
        }
     }
}