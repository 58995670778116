#kyc-div {
    .kyc-content {
        //padding-top: 50px;
    }

    table {
        width: 100% !important;
    }

    #usTaxResNew {
        font-style: normal;
        width: 250px !important;
        display: inline;
        padding: 5px;
        border: 3px solid #000;
        text-align: center;
        font-weight: 700;
        border-radius: 8px;
        height: 50px;
    }

    .update-profile-button {
        height: 50px;
        border-radius: 8px;
        color: #fff;
        background-color: #FFC81C;
        border-style: none;
        text-decoration: none;
        cursor: pointer;
        width: 300px !important;
        text-align: center;
        vertical-align: middle;
        padding: 8px;
        margin-top: 20px;
        position: relative;
        top: -60px;
        left: 980px;
        font-family: 'Avenir LT Std Heavy';
    }

    #missing-profile-details {
        font-size: 16px;
        font-family: 'Avenir LT Std Heavy';
        font-weight: 700;
        line-height: 19px;
        padding-bottom: 20px;
        padding-top: 20px;
        color: #cc0202;
        border: 3px solid #cc0202;
        text-align: center !important;
        display: flex;
        align-items: center;
        padding-left: 20px;
    }

    @media only screen and (max-width: 768px) {

        table,
        thead,
        tbody,
        th,
        td,
        tr {
            // display: block;
            padding: 5px !important;
            width: 100% !important;
            min-width: 100% !important;
            padding: 0 !important;
        }

        tr,
        td,
        th {
            padding: 0 5px 20px 5px !important;
            font-size: 14px !important;
            line-height: 20px !important;
        }

        .update-profile-button {
            font-size: 10px !important;
            width: 115px !important;
            max-width: none !important;
            left: 225px !important;
        }
    }
}