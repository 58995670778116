.main-box {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.pointer {
  cursor: pointer;
}

.userSelect {
  user-select: none;
}

p {
  margin: 0;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.policy {
  padding-top: 120px;
  background: #f5f7fa;

  ul li {
    list-style-type: none;
  }

  .headline {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 46px;
    padding-bottom: 32px;

    p {
      color: #003448;
      font-family: Avenir;
      font-size: 24px;
      font-weight: 350;
      line-height: 31px;
      /* 129.167% */
      letter-spacing: 1px;
    }
  }

  .box {
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 80px;

    .tible {
      font-family: Avenir;
      font-size: 26px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: #5582a0;
      margin: 20px 0;
    }

    .content {
      font-family: Avenir;
      font-size: 16px;
      font-weight: 300;
      line-height: 25px;
      letter-spacing: 0px;
      text-align: left;
      color: #003448;
    }
  }
}

@media only screen and (max-width: 768px) {
  .policy .headline {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0;
  }

  .policy .box {
    padding: 0 20px;
    padding-bottom: 30px;
  }
}