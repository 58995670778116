.loginInformation {
  margin-top: 120px;

  .btn-outline-next {
    --bs-btn-color: #fff;
    --bs-btn-bg: #003448;
    --bs-btn-border-color: #003448;
    --bs-btn-hover-color: #003448;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #003448;
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: #003448;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #003448;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #fff;
    --bs-gradient: none;
  }

  .btn-outline-link {
    --bs-btn-font-weight: 400;
    --bs-btn-color: #5582a0;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #5582a0;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: #5582a0;
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: 0 0 0 #000;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    text-decoration: underline;
  }

  .headline-box {
    padding-top: 80px;
    padding-bottom: 80px;

    .headline {
      color: #003448;
      text-align: center;
      font-family: Berthold Akzidenz Grotesk;
      font-size: 36px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      white-space: nowrap;
      margin: 0 6px;
    }

    .line {
      width: 100%;
      height: 3px;
      background-color: #003448;
    }
  }

  .content-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-check-input:checked {
      background-color: #5582a0;
      border-color: #5582a0;
    }

    .form-check-input {
      width: 24px;
      height: 24px;
    }

    .radio {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #f1eeea;
      border: none;
      margin-right: 12px;
    }

    .radio-check {
      background-color: #5582a0;

      .check {
        width: 12px;
        height: 12px;
        background: #fff;
        border-radius: 50%;
      }
    }

    .content {
      max-width: 615px;
      padding: 16px;
      padding-right: 16px;

      .m-b-32 {
        margin-bottom: 32px;
      }

      .promote {
        color: var(--Dark-Blue, #003448);
        text-align: center;

        /* Body */
        font-family: Avenir LT Std;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        /* 150% */
      }

      .accountTitle {
        color: var(--Dark-Blue, #003448);
        text-align: center;

        /* Sub-title */
        font-family: Avenir LT Std;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 16px;
      }

      .usertype {
        margin-bottom: 32px;

        p {
          color: #003448;
          font-feature-settings: 'clig' off, 'liga' off;

          /* Question/Remark */
          font-family: Avenir LT Std;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          margin-bottom: 12px;
          text-align: start;
        }

        .form-check-label {
          color: var(--Dark-Blue, #003448);
          font-feature-settings: 'clig' off, 'liga' off;
          /* Question/ANS */
          font-family: Avenir LT Std;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
          margin-left: 12px;
        }
      }

      .tabs {
        margin-bottom: 32px;

        .tabsName {
          width: 50%;
          display: flex;
          justify-content: center;
          padding: 8px 0;
          position: relative;

          p {
            color: rgba(85, 130, 160, 0.5);

            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Avenir;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
            margin-bottom: 16px;
          }
        }

        .tabsName::before {
          content: '';
          width: 100%;
          height: 3px;
          background: rgba(85, 130, 160, 0.5);
          position: absolute;
          bottom: 0;
        }

        .checked {
          p {
            color: var(--Dark-Blue, #003448);
            font-feature-settings: 'clig' off, 'liga' off;
            /* Menu/Title_selected */
            font-family: Avenir LT Std;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            /* 150% */
          }
        }

        .checked::before {
          background: #003448;
        }
      }

      .clientid {
        display: flex;
        flex-direction: column;

        span {
          margin-bottom: 12px;
          color: var(--Dark-Blue, #003448);
          font-feature-settings: 'clig' off, 'liga' off;
          /* Question/Remark */
          font-family: Avenir LT Std;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .clientid-input {
          border: none;
          border-bottom: 1px solid #000;
          height: 28px;
          color: var(--Grey, #9b9b9b);
          font-family: Avenir;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          cursor: pointer;
          margin-bottom: 16px;
        }

        .clientid-input:focus {
          outline: none;
          border-bottom: 2px solid #000;
        }
      }

      .Terms {
        display: flex;
        align-items: center;

        p {
          color: var(--Mid-Blue, #5582a0);
          font-family: Average;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          /* 128.571% */
          margin-left: 8px;
        }
      }

      .nextBtn {
        padding: 10px 150px;
        width: 100%;
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .loginInformation {
    .headline-box {
      padding-top: 20px;
      padding-bottom: 0px;

      .line {
        width: 10%;
        height: 2px;
      }

      .headline {
        font-size: 24px;
        line-height: 26px;
        padding: 20px;
        white-space: normal;
        padding-top: 10px;
      }
    }

    .content-box .content {
      padding: 20px !important;

      .next-btn {
        width: 100%;
      }

      .nextBtn {
        padding: 0;
      }
    }
  }
}