.main-box {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.pointer {
  cursor: pointer;
}

.userSelect {
  user-select: none;
}

p {
  margin: 0;
}

.investments {
  padding-top: 120px;
  background: #f5f7fa;

  .btn-outline-theme {
    --bs-btn-color: #003448;
    --bs-btn-border-color: #003448;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #003448;
    --bs-btn-hover-border-color: #003448;
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #003448;
    --bs-btn-active-border-color: #003448;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #003448;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #003448;
    --bs-gradient: none;
  }

  .btn-link-theme {
    --bs-btn-font-weight: 800;
    --bs-btn-color: #003448;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #003448;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: #003448;
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: 0 0 0 #000;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    text-decoration: underline;
  }

  .headline {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 46px;
    padding-bottom: 32px;

    p {
      color: #003448;
      font-family: Avenir;
      font-size: 24px;
      font-weight: 350;
      line-height: 31px;
      /* 129.167% */
      letter-spacing: 1px;
    }
  }

  .statement-box {
    margin-left: 80px;
    margin-right: 80px;

    border-radius: 5px;
    border: 1px solid rgba(219, 223, 227, 0.3);
    background: #fff;
    box-shadow: 0px 3px 12px 5px rgba(217, 226, 233, 0.5);
    padding: 40px 36px;

    .name {
      p {
        color: #003448;
        font-family: Avenir;
        font-size: 20px;

        font-weight: 350;
        line-height: 27px;
        text-transform: uppercase;
      }
    }

    .id {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    span {
      color: #5582a0;
      font-feature-settings: 'clig' off, 'liga' off;

      /* Body */
      font-family: Avenir LT Std;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      /* 150% */
    }

    .updatabtn {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  .tabs {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;
    margin-bottom: 40px;

    .tabsName {
      width: 50%;
      display: flex;
      justify-content: center;
      padding: 11px 0;
      position: relative;

      p {
        color: #9b9b9b;
        text-align: center;
        font-family: Avenir;
        font-size: 18px;
        font-style: normal;
        font-weight: 350;
        line-height: 24px;
        /* 133.333% */
        text-transform: uppercase;
      }
    }

    .checked {
      p {
        color: #003448 !important;
      }
    }

    .checked::before {
      content: '';
      width: 100%;
      height: 3px;
      background: #003448;
      position: absolute;
      bottom: 0;
    }
  }

  .tabsContent {
    margin-left: 80px;
    margin-right: 80px;
    padding-bottom: 160px;

    .enquiry {
      text-align: center;
    }

    .enquiries {
      font-family: Avenir;
      font-size: 16px;
      font-weight: 300;
      line-height: 25px;
      text-align: center;
      padding: 0 25%;
    }

    .Required {
      font-family: Avenir;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: center;
      padding: 20px 0;
    }

    .submit {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 29px;
      padding-right: 29px;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      background-color: #0d3448;
      color: white;
      font-family: Avenir LT Std;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
    }

    .from {
      padding: 0 20%;

      .Container {
        padding: 20px;

        .fromtitle {
          font-family: Avenir LT Std;
          font-size: 16px;
          font-weight: 300;
          line-height: 19px;
          letter-spacing: 0px;
          text-align: left;
        }

        .fromtitleNone {
          color: #f5f7fa;
        }

        .css-1h06qz8-control {
          border: none;
          border-bottom: 1px solid #000;
          border-radius: 0;
          background-color: #f5f7fa;
          height: 50px;
        }

        .css-1ud0aji-control {
          border: none;
          border-bottom: 1px solid #000;
          border-radius: 0;
          background-color: #f5f7fa;
          height: 50px;
          box-shadow: none;
        }

        .css-1h06qz8-control:hover {
          background-color: #f5f7fa;
        }

        input {
          border: none;
          border-bottom: 1px solid #000;
          color: #9b9b9b;
          cursor: pointer;
          font-family: Avenir;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          height: 28px;
          line-height: normal;
          transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          background-color: #f5f7fa;
          width: 100%;
          height: 50px;
        }

        input:focus {
          border-bottom: 2px solid #000;
          outline: none;
        }
      }
    }

    .total-box {
      padding: 40px 36px;
      background: #e4eef2;

      .time {
        color: #003448;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Avenir LT Std;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        margin-bottom: 12px;
      }

      .total {
        border-radius: 5px;
        padding-top: 32px;
        padding-bottom: 32px;
        margin-bottom: 12px;

        p {
          color: #fff;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;

          /* Title */
          font-family: Avenir LT Std;
          font-size: 24px;
          font-style: normal;
          font-weight: 350;
          line-height: 31px;
          /* 129.167% */
          letter-spacing: 1px;
        }

        .amount-box {
          .amount {
            color: #fff;
            text-align: center;

            /* Input */
            font-family: Avenir LT Std;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            span {
              margin-left: 5px;
              color: #fff;
              font-family: Avenir;
              font-size: 18px;
              font-style: normal;
              font-weight: 800;
              line-height: normal;
            }
          }

          .amount-left {
            position: absolute;
            right: 24px;
            top: -3px;
          }

          .amount-right {
            position: absolute;
            left: 24px;
            top: -3px;
          }

          .vertical {
            width: 2px;
            height: 18px;
            background: #fff;
            margin-top: 10px;
            position: relative;
          }
        }
      }

      .total:last-child {
        margin-bottom: 0;
      }

      .total-bg1 {
        background: #003448;
      }

      .total-bg2 {
        background: #5582a0;
      }

      .total-bg3 {
        background: #9b9b9b;
      }
    }

    .pending-box {
      padding-bottom: 10px;

      th {
        padding-top: 24px;
        padding-bottom: 24px;
      }

      th:last-child {
        padding-right: 32px;
      }

      th:first-child {
        padding-left: 32px;
      }

      td {
        padding-top: 24px;
        padding-bottom: 24px;
      }

      td:last-child {
        padding-right: 32px;
      }

      td:first-child {
        padding-left: 32px;
      }

      .Pending {
        margin-top: 40px;

        p {
          color: #003448;
          font-feature-settings: 'clig' off, 'liga' off;

          /* Sub-title */
          font-family: Avenir LT Std;
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
        }
      }

      .table-box {
        margin: 24px 0;
        border-radius: 5px;
        padding-bottom: 30px;

        .name {
          color: #003448;
          font-family: Avenir LT Std;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 24px;
          /* 150% */
        }

        .place {
          color: #003448;
          font-family: Avenir LT Std;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 24px;
          /* 150% */
        }

        .amount {
          color: #003448;
          font-family: Avenir LT Std;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          /* 150% */
        }

        .date {
          color: #003448;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Avenir LT Std;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          /* 150% */
        }

        .status {
          color: #003448;
          font-family: Avenir LT Std;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          /* 150% */
        }
      }

      .list-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .name {
          padding: 4px 32px;
          background: #003448;
          color: #fff;
          font-feature-settings: 'clig' off, 'liga' off;

          /* Sub-title */
          font-family: Avenir LT Std;
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
        }

        .place {
          color: #003448;
          font-family: Avenir LT Std;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px;
        }

        .amount {
          color: #003448;
          font-feature-settings: 'clig' off, 'liga' off;

          /* Session_title */
          font-family: Avenir LT Std;
          font-size: 18px;
          font-style: normal;
          font-weight: 750;
          line-height: 20px;
          /* 111.111% */
        }

        .date {
          color: #003448;
          font-feature-settings: 'clig' off, 'liga' off;

          /* Session_title */
          font-family: Avenir LT Std;
          font-size: 18px;
          font-style: normal;
          font-weight: 750;
          line-height: 20px;
          /* 111.111% */
        }

        .status {
          color: #003448;
          font-feature-settings: 'clig' off, 'liga' off;

          /* Session_title */
          font-family: Avenir LT Std;
          font-size: 18px;
          font-style: normal;
          font-weight: 750;
          line-height: 20px;
          /* 111.111% */
        }

        .list {
          width: 622px;
          height: 217px;
          border-radius: 5px;
          border: 1px solid rgba(219, 223, 227, 0.3);
          background: #fff;
          box-shadow: 0px 3px 12px 5px rgba(217, 226, 233, 0.5);
          margin-top: 24px;

          .list-label {
            margin-top: 28px;
          }

          .list-padding {
            padding: 8px 32px 24px;
          }

          .divider {
            height: 1px;
            width: 100%;
            margin-top: 24px;
            margin-bottom: 24px;
            background-color: #d9d9d9;
          }

          .list-content {
            max-width: 234px;
          }
        }

        .list:last-child {
          margin-bottom: 24px;
        }
      }
    }
  }

}

.table-body {
  .table {
    tr {
      .address-text {
        .icon-location {
          display: none;
        }
      }

      .icon-email,
      .icon-phone {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .investments {
    .tabsContent .from {
      padding: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .m-t-26 {
    margin-top: 26px;
  }

  .investments {
    // background: #fff;

    .tabsContent {
      padding-bottom: 60px;
      padding-top: 12px;

      .from {
        .Container {
          input {
            background-color: transparent;
          }

          .css-1h06qz8-control {
            background-color: transparent;
          }
        }
      }
    }
  }

  .investments .headline {
    padding: 0 20px;
  }

  .investments .tabs {
    margin: 20px;
  }

  .investments .tabsContent {
    margin: 0 20px;
  }

  .investments .tabsContent .enquiries {
    padding: 0;
  }

  .investments .tabsContent .from {
    padding: 0;
  }

  .investments .tabsContent .from .Container {
    padding: 20px 0;
  }

  .investments .tabsContent .submit {
    width: 100%;
  }


  .table-body {
    max-width: 100%;
    padding-top: 0;

    .mobile-thead {
      display: none;
    }

    .table {
      border: none;

      tr {
        padding: 20px;
        height: auto;
        display: flex;
        flex-direction: column;
        background-color: #F2F7F9;
        margin-bottom: 16px;

        .country-text {
          font-size: 16px;
          color: #5582A0;
        }

        .city-text {
          font-size: 20px;
          color: #003448;
          font-weight: 800;
        }

        .address-text {
          display: flex;
          font-size: 14px;
          color: #003448;

          .icon-location {
            display: inline-block;
            font-size: 20px;
            margin-right: 5px;
          }
        }

        .email-text,
        .phoneNumber-text {
          display: flex;
          align-items: center;
          padding: 5px;
          font-size: 14px;

          i {
            display: inline-block;

            margin-right: 5px;
          }

          .icon-phone {
            font-size: 16px;
          }

          .icon-email {
            font-size: 20px;
          }
        }
      }

      td {
        padding: 5px;
        background-color: transparent;
        border: none;
      }
    }

  }

}