#exit-ballot-div {
    .exit-ballot-content {
        padding-top: 50px;
    }
    a {
        height: 50px;
        border-radius: 8px;
        color: #fff;
        background-color: #FFC81C;
        border-style: none;
        text-decoration: none;
        cursor: pointer;
        width: 350px !important;
        text-align: center;
        padding: 8px;
        display: block;
        margin-top: 20px;
        font-family: 'Avenir LT Std Heavy';
    }
    table {
        width: 100% !important;
    }

    input[type="radio"] {
        height: 24px;
        width: 24px;
        margin: 10px;
    }

    @media only screen and (max-width: 768px) {
        table,
        thead,
        tbody,
        th,
        td,
        tr {
            padding: 5px !important;
            width: 100% !important;
            min-width: 100% !important;
            padding: 0 !important;
            font-size: 14px !important;
        }
     }
}