@import '../../styles/variables.scss';

.main-box {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.pointer {
  cursor: pointer;
}

.userSelect {
  user-select: none;
}

p {
  margin: 0;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.inbox {

  @media only screen and (max-width: 768px) {
    .inbox-header {
      display: none;
    }

    .inbox-wrapper {
      margin: 30px 0 20px 0 !important;
      width: 100% !important;
    }

    .inbox-title {
      padding-top: 40px;
      margin-top: 150px;
    }

    
    .filterSortby {
      .linkBtn {
        width: 60px;
      }

      .noumenon {
        height: 240px !important;
        min-height: 240px !important;
        position: relative;
        left: -25px;
      }
      .Sort-box {
        position: relative;
        left: 20px !important;

        .Sort {
          position: relative;
          left: -50px;
        }
      }
    }

  }

  .inbox-header {
    height: 116px;
    background: linear-gradient(90deg, #003448 15.9%, rgba(0, 52, 72, 0) 92.01%), url('../../../public/inbox-header.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    top: -77px;
    left: 454px;
  }

  .inbox-wrapper {
    width: 1280px;
    height: 116px;
    background: #003448;
    margin: 30px 80px 30px 80px;

    div {
      p {
        color: #fff !important;
        font-family: 'Avenir LT Std Book';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
      }
    }
  }

  padding-top: 120px;
  background: #f5f7fa;
  padding-bottom: 80px;

  .btn-outline-theme {
    --bs-btn-color: #003448;
    --bs-btn-border-color: #003448;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #003448;
    --bs-btn-hover-border-color: #003448;
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #003448;
    --bs-btn-active-border-color: #003448;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #003448;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #003448;
    --bs-gradient: none;
  }

  .btn-outline-next {
    --bs-btn-color: #fff;
    --bs-btn-bg: #003448;
    --bs-btn-border-color: #003448;
    --bs-btn-hover-color: #003448;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #003448;
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: #003448;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #003448;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #fff;
    --bs-gradient: none;
  }

  .btn-link-theme {
    --bs-btn-font-weight: 800;
    --bs-btn-color: #003448;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #003448;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: #003448;
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: 0 0 0 #000;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    text-decoration: underline;
  }

  .headline {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 46px;
    padding-bottom: 32px;

    p {
      color: #003448;
      font-family: 'Avenir LT Std Medium';
      font-size: 26px;
      font-weight: 350;
      line-height: 31px;
      /* 129.167% */
      letter-spacing: 1px;
    }
  }

  .submit-box {
    height: 42px;
    margin-left: 80px;
    margin-right: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .searchControl {
      width: 100%;
      max-width: 65%;
      display: flex;

      .form-control {
        height: 42px;
      }
    }

    .search-box {
      display: flex;
    }

    .form-control::-webkit-input-placeholder {
      color: #003448;
      font-family: 'Avenir LT Std Medium';
      font-size: 14px;
      font-weight: 400;
      opacity: 0.3;
      margin-top: 13px;
      margin-bottom: 7px;
      padding-left: 20px;
    }

    .searchBtn {
      margin-left: 20px;
      margin-right: 20px;
      height: 42px;
      width: 100px;
      font-family: 'Avenir LT Std Medium';
      font-size: 16px;
      font-weight: 700;
    }

    .linkBtn {
      color: #003448;
      text-align: center;
      font-family: 'Avenir LT Std Medium';
      font-size: 16px;
      font-weight: 800;
    }

    .linkBtn:hover+.block {
      display: flex;
    }

    .block {
      display: none;
    }

    .Sort-box:hover {
      display: flex;
    }

    .Sort-box {
      position: absolute;
      flex-direction: column;
      align-items: center;
      right: -50%;
      top: 25px;
      z-index: 100;

      .arrows {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        border-bottom: 10px solid white;
        z-index: 101;
      }

      .Sort {
        padding: 10px 0;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

        li {
          list-style: none;
          line-height: 36px;
          padding: 0 20px;
          margin: 0;
          font-size: 14px;
          color: #606266;
          cursor: pointer;
          outline: 0;
          text-align: center;
        }

        li:hover {
          background-color: #00344812;
          color: #003448c2;
        }
      }
    }

    .filterSortby {
      position: relative;
      display: flex;
      align-items: center;

      .fly-mid {
        margin-left: 16px;
        margin-right: 16px;
      }

      .asc {
        transform: rotate(-180deg);
      }

      .btn {
        padding: 0;
      }

      .filter {
        color: #003448;
        text-align: center;
        font-family: 'Avenir LT Std Medium';
        font-size: 16px;
        font-weight: 800;
        background-color: #ffffff00 !important;
      }

      .dropdown-toggle::after {
        border: 0;
      }

      .aaa {
        .ApplyBtn {
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 29px;
          padding-right: 29px;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;

          /* button */
          font-family: 'Avenir LT Std Medium';
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;
          margin-left: 16px;
        }

        .ResetBtn {
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 29px;
          padding-right: 29px;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;

          /* button */
          font-family: 'Avenir LT Std Medium';
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;
        }

        .arrows {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
          border-bottom: 10px solid white;
          z-index: 101;
          position: absolute;
          top: -17px;
          left: 45%;
        }

        .relative {
          position: relative;
        }

        .noumenon {
          width: 348px;
          height: 179px;
          background: #fff;
          filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.2));
          z-index: 100;
          border-radius: 5px;
          padding: 24px;
        }

        .btn-box {
          margin-top: 16px;
        }
      }

      // .notification:hover {
      //   display: flex;
      // }

      .filterBox {
        height: 379px;
        width: 348px;
        padding-bottom: 379px;
      }

      .notification {
        display: flex;
        position: absolute;
        flex-direction: column;
        align-items: center;
        right: -15%;
        top: 25px;
        z-index: 100;

        .ApplyBtn {
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 29px;
          padding-right: 29px;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;

          /* button */
          font-family: 'Avenir LT Std Medium';
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;
          margin-left: 16px;
        }

        .ResetBtn {
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 29px;
          padding-right: 29px;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;

          /* button */
          font-family: 'Avenir LT Std Medium';
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;
        }

        .arrows {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
          border-bottom: 10px solid white;
          z-index: 101;
        }

        .noumenon {
          width: 348px;
          min-height: 450px;
          background: #fff;
          filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.2));
          z-index: 100;
          border-radius: 5px;
          padding: 24px;
        }

        .btn-box {
          margin-top: 16px;
          position: absolute;
          bottom: 24px;
          right: 24px;
        }
      }
    }
  }

  .list-box {
    .tabs-box {
      margin-top: 32px;
      margin-left: 80px;
      margin-right: 80px;

      .tabs {
        .checked-box {
          width: 84px;
          height: 40px;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          user-select: none;
          cursor: pointer;

          .checked {
            color: #003448;
            font-feature-settings: 'clig' off, 'liga' off;

            /* Menu/Title_selected */
            font-family: 'Avenir LT Std Medium';
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            /* 150% */
          }
        }

        .checked-box::before {
          content: '';
          width: 100%;
          height: 3px;
          background: $yellow;
          position: absolute;
          bottom: 0;
        }

        .unselected-box {
          width: 84px;
          height: 40px;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          user-select: none;
          cursor: pointer;

          .unselected {
            color: #5582a0;
            text-align: center;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: 'Avenir LT Std Medium';
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
            text-transform: capitalize;
          }
        }

        .unselected-box::before {
          content: '';
          width: 100%;
          height: 1px;
          background: #5582a0;
          position: absolute;
          bottom: 0;
        }
      }

      .clickBtn {
        margin: 10px 24px;
      }

      .deletebtn-show {
        display: flex;
        align-items: center;
        margin-left: 24px;
      }

      .deletebtn {
        display: none;
      }
    }

    .card-box {
      padding: 32px 0;

      .list {
        margin-left: 80px;
        margin-right: 80px;

        .list-click {
          margin-left: 24px;
          margin-right: 24px;
        }

        .selectCard {
          background-color: #d7efff;
          stroke-width: 1px;
          stroke: rgba(219, 223, 227, 0.3);
          filter: drop-shadow(0px 3px 12px rgba(217, 226, 233, 0.5));
        }

        .notcard {
          background-color: #fff;
          stroke-width: 1px;
          stroke: rgba(219, 223, 227, 0.3);
          filter: drop-shadow(0px 3px 12px rgba(217, 226, 233, 0.5));
        }

        .list-card {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          padding: 24px;
          margin-top: 16px;
          margin-bottom: 16px;

          .label {
            display: flex;
            justify-content: space-between;

            span {
              border-radius: 10px;
              background: #5582a0;
              padding: 1px 8px;
              color: #fff;
              /* Input */
              font-family: 'Avenir LT Std Medium';
              font-size: 14px;
              font-weight: 400;
              // line-height: 17px;
            }
          }

          .content {
            margin-top: 8px;
            margin-bottom: 26px;

            P {
              color: #003448;
              font-family: 'Avenir LT Std Medium';
              font-size: 16px;
              font-weight: 800;
              line-height: 22px;
              /* 137.5% */
            }
          }

          .time {
            span {
              color: #5582a0;
              font-family: 'Avenir LT Std Medium';
              font-size: 14px;
              font-weight: 500;
              line-height: 19px;
            }
          }
        }

        .list-card:last-child {
          margin-bottom: 0;
        }

        .list-card:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {}

@media only screen and (max-width: 768px) {
  .inbox {
    .submit-box {
      margin-left: 20px;
      margin-right: 20px;

      .searchBtn {
        display: none;
      }

      .searchControl {
        width: 100%;
        max-width: 100%;
      }

      .clear-btn {
        display: none;
      }
    }

    .headline {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 0;
    }

    .list-box {
      .tabs-box {
        margin-left: 20px;
        margin-right: 20px;

        .clickBtn {
          margin: 10px 0;
        }

        .tabs {
          width: 100%;
          margin-left: 8px;

          .checked-box {
            width: 50%;
          }

          .unselected-box {
            width: 50%;
          }
        }
      }

      .card-box {
        .list {
          margin-left: 20px;
          margin-right: 20px;

          .list-click {
            margin-right: 8px;
            margin-left: 0;
          }
        }
      }
    }
  }
}