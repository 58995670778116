#exit-summary-div {
    font-family: 'Avenir LT Std Heavy';

    .exit-summary-content {
        padding-top: 20px;

        p {
            font-weight: 500 !important;
        }
    }

    .border-red {
        border: 1px solid rgb(223, 15, 15);
    }

    .ballot-table {
        border: solid #ddd 1px;
        border-radius: 25px;
        vertical-align: middle;
        text-align: center;
        width: 100%;
        margin-bottom: 40px;
        border-left: none;
        border-right: none;
    }

    .ballot-table td,
    .ballot-table th {
        font-size: 21px;
        padding: 10px 25px;
        //font-weight: 500;
    }

    .ballot-table th {
        background-color: #5582A0;
        color: #FAF9F6;
        border-top: none;
    }

    .ballot-table tbody tr {
        border-bottom: 2px solid #003448;
    }

    .signature-div {
        padding-top: 20px;
    }

    .sigCanvas {
        width: 100%;
        min-height: 150px;
    }

    .signature-title {
        font-size: 24px;
        text-transform: uppercase;
    }

    .clear-button-div {
        position: relative;
        top: -60px;
        left: -10px;

        button {
            background-color: #5582A0 !important;
            height: 36px;
            padding-bottom: 35px !important;
        }

        button:hover {
            color: #fff;
        }
    }

    .offer-summary {
        margin-top: 15px;

        p {
            font-size: 24px !important;
        }
    }

    .summary-table {
        td {
            padding-right: 50px;
            padding-bottom: 0;
            //font-weight: 600;
            font-size: 21px;
        }

        margin-bottom: 30px;
    }

    @media only screen and (max-width: 768px) {

        table,
        thead,
        tbody {
            //display: block;
            padding: 5px !important;
            width: 100% !important;
            min-width: 100% !important;
            padding: 0 !important;
        }

        tr,
        td,
        th {
            padding: 0 5px 20px 5px !important;
            font-size: 14px !important;
            line-height: 20px !important;
        }


        .clear-button-div {
            top: -70px !important;

            button {
                width: 70px !important;
                height: 20px !important;
                line-height: 20px !important;
                font-size: 14px !important;
                padding-bottom: 30px !important;
                padding-left: 20px;
            }
        }

        .sigCanvas {
            margin-bottom: 20px !important;

            height: 150px;
        }
    }
}