.modal-box {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #003448;
    padding: 20px;

    .text-title {
        font-size: 22px;
        font-weight: 800;
        margin-top: 10px;
    }

    .text-center {
        font-size: 16px;
        text-align: center;
        margin-top: 16px;
    }

    .confirm-btn {
        width: 100%;
        margin-top: 40px;

        button {
            height: 50px;
            border-radius: 8px;
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    .icon-box {
        font-size: 80px;
        color: #5582A0;
    }
}