.exit-voting-stepper {


    @media only screen and (max-width: 768px) {
        width: 100%;
        // overflow-x: scroll;
        .MuiBox-root {
            width: 100% !important;
        }

        .stepper-content {
            width: 100% !important;
            padding: 5px 20px !important;
        }

        .stepper-footer{
            padding: 5px 40px !important;
        }

        .stepper-title {
            margin-bottom: 20px;
        }

        button {
            font-size: 10px !important;
            width: 100% !important;
            max-width: none !important;
        }
    }

    border: 1px solid #ddd;
    box-shadow: 0px 3px 12px 5px rgba(217, 226, 233, 0.5);
    border-radius: 5px;
    padding-top: 30px;
    margin-bottom: 10px;
    font-family: 'Avenir LT Std Book';


    .stepper-content {
        margin-top: 20px;
        min-height: 400px;
        padding: 20px 80px;
    }

    .stepper-footer {
        padding: 20px 100px;
    }

    button {
        height: 50px;
        border-radius: 8px;
        margin-bottom: 0;
        margin-top: 0;
        color: #fff;
        background-color: #FFC81C;
        border-style: none;
        padding-right: 30px;
        padding-left: 30px;
        margin-bottom: 20px;
        float: right;
        font-size: 21px;
        font-family: 'Avenir LT Std Heavy';
    }

    button:disabled {
        background-color: #ccc;
        color: #666;
        opacity: 0.6;
    }

    .stepper-title {
        text-align: left;
        //font-family: 'Avenir';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 31px;
        /* identical to box height, or 78% */
        letter-spacing: 1px;
        text-transform: uppercase;
        font-family: 'Avenir LT Std Heavy';

        color: #003448;
    }

    .stepper-p {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 33px;
    }

    .MuiSvgIcon-root.Mui-completed {
        color: #0bbe0b;
        cursor: pointer;
    }

    .MuiSvgIcon-root.Mui-active {
        color: #5582A0;

        circle {
            height: 20px;
        }
    }

    .grayed-out {
        color: #9E9E9E !important;
    }

    .MuiStepLabel-label.Mui-active {
        font-size: 21px;
        margin-top: 5px;
        font-family: 'Avenir LT Std Heavy';
    }
}