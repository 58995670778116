.main-box {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.padding-main {
  padding-right: 80px;
  padding-left: 80px;
}

.pointer {
  cursor: pointer
}

.userSelect {
  user-select: none;
}

p {
  margin: 0;
}

.inboxDetails {
  padding-top: 120px;

  img {
    width: 100%;
  }

  .headline {
    margin-top: 40px;
    margin-bottom: 24px;
    padding-left: 0;
    padding-right: 0;

    p {
      color: #5582A0;
      font-family: Avenir;
      font-size: 16px !important;
      font-weight: 800;
      line-height: 22px;
      /* 137.5% */
    }
  }

  .list-card {
    .label {
      span {
        color: #5582A0;
        /* Input */
        font-family: Avenir LT Std;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        border-radius: 10px;
        background: #F2F7F9;
        padding: 0 8px;
      }

    }

    .content {
      p {
        color: #003448;
        font-family: Avenir;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 31px;
        /* 129.167% */
        letter-spacing: 1px;
        margin: 16px 0;
      }
    }

    .time {
      span {
        color: #5582A0;
        font-family: Avenir;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 10px;
      }

    }
  }

  .detailsContent {
    margin-top: 32px;
    margin-bottom: 80px;

    p {
      color: #003448;
      font-feature-settings: 'clig' off, 'liga' off;

      /* Body */
      font-family: Avenir LT Std;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      /* 150% */
    }
  }
}

.inbox-img {
  height: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
}

.button-box {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 100px;
  margin-top: 100px;
}

.submit {
  width: 360px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 29px;
  padding-right: 29px;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  background-color: #0d3448;
  color: white;
  font-family: Avenir LT Std;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}

.btn-outline-next {
  --bs-btn-color: #fff;
  --bs-btn-bg: #003448;
  --bs-btn-border-color: #003448;
  --bs-btn-hover-color: #003448;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #003448;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #003448;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #003448;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff;
  --bs-gradient: none;
}

@media only screen and (max-width: 768px) {
  .main-box {
    max-width: 100% !important;
  }

  .padding-main {
    padding-left: 20px;
    padding-right: 20px;
  }

  .inboxDetails {
    .headline {
      margin-top: 0;
      padding-top: 0;
      margin-bottom: 0;
      padding-bottom: 24px;
    }
  }

  .button-box {
    flex-direction: column;
    row-gap: 20px;

    .submit {
      width: 100%;
    }
  }
}