.form {
  position: absolute;
  top: 45%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 100%;
  height: 100%;
  padding: 36px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);

  button {
    width: 100%;
  }

  p {
    color: rgb(204, 204, 204);
    text-align: center;
    margin-top: 16px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }
}

.table-border {
  // border-color: inherit;
  border-style: solid;
  border-width: 1px;
}

.title {
  color: #003448;
  padding-top: 35px;
  padding-bottom: 35px;
  font-size: 24px;
}

.background {
  background-color: #f5f7fa;

}

.float-right {
  float: right
}

.float-left {
  float: left
}

.table-body {
  padding-top: 35px;
  padding-bottom: 35px;
}

.searh-row {
  padding-top: 35px;
  padding-bottom: 35px;
}

.table {
  td {
    color: #003448;
  }

  tr {
    height: 72px;
  }

  th {
    color: #b4b4b4;
  }
}

.fly-mid {
  display: inline-block;
  height: 10px;
  width: 1px;
  vertical-align: middle;
  background-color: #e2e2e2;
}

.searchBtn {
  margin-left: 20px;
  margin-right: 20px;
  height: 42px;
  width: 100px;
  font-family: Avenir LT Std;
  font-size: 16px;
  font-weight: 700;
}

.btn-outline-theme {
  --bs-btn-color: #003448;
  --bs-btn-border-color: #003448;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #003448;
  --bs-btn-hover-border-color: #003448;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #003448;
  --bs-btn-active-border-color: #003448;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #003448;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #003448;
  --bs-gradient: none;
}

.clientCard {
  border: none;
  border-radius: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;

  @media only screen and (max-width: 768px) {
    text-align: left;
  }

  color: #003448;
}

.client-card-header {
  background-color: #5582A0;
  color: #fff;
  font-family: 'Avenir LT Std Medium';
  font-weight: 350;
  font-size: 14px;
  line-height: 22px;
}

.option {
  padding-bottom: 24px;
}

.viewClient {

  @media only screen and (max-width: 768px) {
    padding: 40px 20px 20px 20px;
  }

  padding-top: 120px;
  background: #F5F7FA;
  font-family: 'Avenir LT Std Book';

  .btn-outline-theme {
    --bs-btn-color: #003448;
    --bs-btn-border-color: #003448;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #003448;
    --bs-btn-hover-border-color: #003448;
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #003448;
    --bs-btn-active-border-color: #003448;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #003448;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #003448;
    --bs-gradient: none;
  }

  .btn-outline-next {
    --bs-btn-color: #fff;
    --bs-btn-bg: #003448;
    --bs-btn-border-color: #003448;
    --bs-btn-hover-color: #003448;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #003448;
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: #003448;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #003448;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #fff;
    --bs-gradient: none;
  }

  .btn-link-theme {
    --bs-btn-font-weight: 800;
    --bs-btn-color: #003448;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #003448;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: #003448;
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: 0 0 0 #000;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    text-decoration: underline;
  }

  @media only screen and (max-width: 768px) {
    .viewclient-header {
      display: none;
    }

    .viewclient-wrapper {
      margin: 30px 0 20px 0 !important;
      width: 100% !important;
    }

    padding-top: 80px;
  }


  .viewclient-wrapper {
    width: 1280px;
    height: 116px;
    background: #003448;
    margin: 30px 80px 30px 80px;

    div {
      p {
        color: #fff !important;
        font-family: 'Avenir LT Std Book';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
      }
    }
  }

  .headline {

    @media only screen and (max-width: 768px) {
      padding-bottom: 0;
    }


    padding-left: 80px;
    padding-right: 80px;
    padding-top: 46px;
    padding-bottom: 32px;

    p {
      color: #003448;
      // font-family: Avenir;
      font-family: 'Avenir LT Std Medium';
      font-size: 26px;
      font-weight: 350;
      line-height: 31px;
      /* 129.167% */
      letter-spacing: 1px;
    }

    .viewclient-header {
      height: 116px;
      background: linear-gradient(90deg, #003448 15.9%, rgba(0, 52, 72, 0) 92.01%), url('../../../public/viewclient-header.jpg');
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
      top: -77px;
      left: 454px;



    }
  }

  .submit-box {
    @media only screen and (max-width: 768px) {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      min-height: 240px;
      padding: 10px 20px 10px 30px;

      input[type="text"] {
        margin-bottom: 10px;
      }

      .filter-search-buttons {
        margin-left: -20px;

        .linkBtn {
          margin: -10px;
        }
      }

      .filterSortby {
        position: relative;
        top: 30px;
      }
    }

    height: 42px;
    margin-left: 80px;
    margin-right: 80px;


    .searchControl {
      width: 100%;
      max-width: 616px;

      .form-control {
        height: 42px;
      }
    }


    .form-control::-webkit-input-placeholder {
      color: #003448;
      // font-family: Avenir;
      font-family: 'Avenir LT Std Book';
      font-size: 14px;
      font-weight: 400;
      opacity: 0.3;
      margin-top: 13px;
      margin-bottom: 7px;
      padding-left: 20px;
    }


    .searchBtn {
      margin-left: 20px;
      margin-right: 20px;
      height: 42px;
      width: 100px;
      // font-family: Avenir LT Std;
      font-family: 'Avenir LT Std Heavy';
      font-size: 16px;
      font-weight: 700;
    }

    .linkBtn {
      color: #003448;
      text-align: center;
      // font-family: Avenir;
      font-family: 'Avenir LT Std Book';
      font-size: 16px;
      font-weight: 800;
    }

    .filterSortby {
      @media only screen and (max-width: 768px) {
        .sort-by-noti {
          right: -20px !important;

          .arrows {
            position: relative;
            left: 120px;
          }
        }
      }


      position: relative;
      display: flex;
      align-items: center;

      .fly-mid {
        margin-left: 16px;
        margin-right: 16px;
      }



      .asc {
        transform: rotate(-180deg);
      }

      .btn {
        padding: 0;
      }

      .hoverFilter:hover+.sort-by-noti {
        display: flex !important;
      }

      .sort-by-noti:hover {
        display: flex !important;
      }

      .sort-by-noti {
        display: none !important;
      }

      .notification {
        display: flex;
        position: absolute;
        flex-direction: column;
        align-items: center;
        right: -15px;

        .ApplyBtn {
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 29px;
          padding-right: 29px;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;

          /* button */
          // font-family: Avenir LT Std;
          font-family: 'Avenir LT Std Heavy';
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;
          margin-left: 16px;
        }

        .ResetBtn {
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 29px;
          padding-right: 29px;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;

          /* button */
          // font-family: Avenir LT Std;
          font-family: 'Avenir LT Std Heavy';
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;
        }

        .arrows {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
          border-bottom: 10px solid white;
          z-index: 101;
        }

        .doc-noumenon {
          @media only screen and (max-width: 768px) {
            min-height: 400px;
          }

          width: 348px;
          min-height: 400px;
          background: #fff;
          filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.2));
          z-index: 100;
          border-radius: 5px;
          padding: 24px;
        }

        .noumenon {
          width: 348px;
          height: 350px;
          background: #fff;
          filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.2));
          z-index: 100;
          border-radius: 5px;
          padding: 24px;
        }

        .btn-box {
          position: absolute;
          margin-top: 16px;
          bottom: 24px;
          right: 24px;
        }
      }
    }
  }

  .list-box {


    .tabs-box {
      margin-top: 32px;
      margin-left: 80px;
      margin-right: 80px;

      .tabs {
        .checked-box {
          width: 84px;
          height: 40px;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          user-select: none;
          cursor: pointer;


          .checked {

            color: #003448;
            font-feature-settings: 'clig' off, 'liga' off;

            /* Menu/Title_selected */
            // font-family: Avenir LT Std;
            font-family: 'Avenir LT Std Heavy';
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            /* 150% */

          }
        }

        .checked-box::before {
          content: '';
          width: 100%;
          height: 3px;
          background: #003448;
          position: absolute;
          bottom: 0;
        }

        .unselected-box {
          width: 84px;
          height: 40px;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          user-select: none;
          cursor: pointer;

          .unselected {

            color: #5582A0;
            text-align: center;
            font-feature-settings: 'clig' off, 'liga' off;
            // font-family: Avenir;
            font-family: 'Avenir LT Std Heavy';
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
            text-transform: capitalize;

          }
        }

        .unselected-box::before {
          content: '';
          width: 100%;
          height: 1px;
          background: #5582A0;
          position: absolute;
          bottom: 0;
        }
      }

      .clickBtn {
        margin: 10px 24px;
      }

      .deletebtn-show {
        display: flex;
        align-items: center;
        margin-left: 24px;
      }

      .deletebtn {
        display: none;
      }
    }

    .card-box {
      padding: 32px 0;

      .list {
        margin-left: 80px;
        margin-right: 80px;

        .list-click {
          margin-left: 24px;
          margin-right: 24px;
        }

        .selectCard {
          background-color: #D7EFFF;
          stroke-width: 1px;
          stroke: rgba(219, 223, 227, 0.30);
          filter: drop-shadow(0px 3px 12px rgba(217, 226, 233, 0.50));
        }

        .notcard {
          background-color: #FFF;
          stroke-width: 1px;
          stroke: rgba(219, 223, 227, 0.30);
          filter: drop-shadow(0px 3px 12px rgba(217, 226, 233, 0.50));
        }

        .list-card {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          padding: 24px;
          margin-top: 16px;
          margin-bottom: 16px;

          .label {
            display: flex;
            justify-content: space-between;

            span {
              border-radius: 10px;
              background: #F2F7F9;
              padding: 1px 8px;
              color: #5582A0;
              /* Input */
              // font-family: Avenir LT Std;
              font-family: 'Avenir LT Std Medium';
              font-size: 14px;
              font-weight: 400;
              line-height: 17px;
            }
          }

          .content {
            margin-top: 8px;
            margin-bottom: 26px;

            P {
              color: #003448;
              // font-family: Avenir;
              font-family: 'Avenir LT Std Heavy';
              font-size: 16px;
              font-weight: 800;
              line-height: 22px;
              /* 137.5% */
            }
          }

          .time {
            span {
              color: #5582A0;
              // font-family: Avenir;
              font-family: 'Avenir LT Std Medium';
              font-size: 14px;
              font-weight: 500;
              line-height: 19px;
            }
          }
        }

        .list-card:last-child {
          margin-bottom: 0;
        }

        .list-card:first-child {

          margin-top: 0
        }
      }
    }
  }
}

.recordzoomin {
  max-height: 150px;
  overFlow: hidden;

  @media only screen and (max-width: 768px) {
    min-height: 270px;

    svg {
      margin-top: 10px;
      margin-left: 15px;

      path {
        stroke-width: 4;
      }
    }
  }
}

.recordzoomout {
  height: auto;
}