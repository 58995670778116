#root {
  height: 100%;
}

.ant-layout {
  height: 100%;
}

.ant-layout-sider {
  background: #fff;
}

.app-container {
  height: 100%;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-32 {
  margin-left: 32px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.m-r-32 {
  margin-right: 32px;
}

.f-s-20 {
  font-size: 20px !important;
}

.Page {
  background-color: #fff;

  .content {
    padding: 30px 0;
  }
}

.form-group {
  margin-bottom: 22px;

  .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
  }
}

.page-link.active,
.active>.page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: #0d3449 !important;
  border-color: #0d3449 !important;
}

.page-link {
  --bs-pagination-color: #0d3449;
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.flex-end {
  justify-content: flex-end;
  flex-direction: row;
}

.pagination-main {
  flex: 1
}


.submit-box {
  height: 42px;
  margin-left: 80px;
  margin-right: 80px;


  .searchControl {
    width: 100%;
    max-width: 616px;

    .form-control {
      height: 42px;
    }
  }

  .searchClientControl {
    width: 100%;
    max-width: 300px;
    padding-right: 10px;

    .form-control {
      height: 42px;
    }
  }


  .form-control::-webkit-input-placeholder {
    color: #003448;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.3;
    margin-top: 13px;
    margin-bottom: 7px;
    padding-left: 20px;
  }


  .searchBtn {
    margin-left: 20px;
    margin-right: 20px;
    height: 42px;
    width: 100px;
    font-family: Avenir LT Std;
    font-size: 16px;
    font-weight: 700;
  }

  .linkBtn {
    color: #003448;
    text-align: center;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
  }


}

.ApplyBtn {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 29px;
  padding-right: 29px;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  /* button */
  font-family: Avenir LT Std;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  margin-left: 16px;
}

.ResetBtn {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 29px;
  padding-right: 29px;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  /* button */
  font-family: Avenir LT Std;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}

.headline {
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 46px;
  padding-bottom: 32px;

  p {
    color: #003448;
    font-family: Avenir;
    font-size: 26px !important;
    font-weight: 350;
    line-height: 31px;
    /* 129.167% */
    letter-spacing: 1px;
  }
}

.main-box {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}




.sort-by-noti {
  right: -150px !important;
}

.doc-noumenon {
  width: 348px;
  height: 350px;
  background: #fff;
  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.2));
  z-index: 100;
  border-radius: 5px;
  padding: 24px;
}

.react-datepicker-wrapper {
  display: flex !important;
  padding: 0;
  border: 0;
}

.headline {
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 46px;
  padding-bottom: 32px;

  p {
    color: #003448;
    font-family: Avenir;
    font-size: 26px;
    font-weight: 350;
    line-height: 31px;
    /* 129.167% */
    letter-spacing: 1px;
  }
}


:where(.css-dev-only-do-not-override-1uweeqc).ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  color: white !important;
  background-color: #0d3448 !important;
  border-color: #0d3448 !important;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 0;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 20px 24px;
}

.custom-modal-body {
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  height: 100%;
  /* 确保 body 占据整个 Modal 的高度 */
}

.nextBtn {
  width: 70%;
  height: 40px;
  font-size: 16px;
  margin-top: 32px;
  margin-bottom: 32px;
  color: white;
  border: 0px;
  background-color: #003448;
}

.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #003448;
  border-radius: 50%;
  margin-right: 8px;
  margin-bottom: 3px;
}

.date-icon {
  margin-right: 9px;
}

.error-message {
  color: #ff4d4f !important;
  font-size: 14px;

  &.green {
    color: green !important;
  }
}

.bg-disabled {
  background-color: #ccc !important;
}

.loading-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999 !important;
  background: rgba(0, 0, 0, 0.2);
}

.ant-spin {
  color: #003448;
}