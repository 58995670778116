#nfc-div {
    .nfc-content {
        padding-top: 50px;
    }

    table {
        width: 100% !important;

        span,
        li::marker {
            font-size: 18px !important;
        }

    }

    #usResState {
        width: 350px !important;
        display: inline;
        border: 3px solid #000;
        text-align: center;
        text-decoration: none !important;
    }

    #citizen {
        height: 21px !important;
        width: 21px;
        border: 2px solid #000;
    }

    #usRes {
        height: 21px !important;
        width: 21px;
        border: 2px solid #000;
    }

    .update-profile-button {
        height: 50px;
        border-radius: 8px;
        color: #fff;
        background-color: #FFC81C;
        border-style: none;
        text-decoration: none;
        cursor: pointer;
        width: 300px !important;
        text-align: center;
        vertical-align: middle;
        padding: 8px;
        margin-top: 20px;
        position: relative;
        top: -60px;
        left: 980px;
        font-family: 'Avenir LT Std Heavy';
    }

    .update-profile-notes {
        font-size: 16px;
        font-family: 'Avenir LT Std Heavy';
        margin-top: -40px;
        font-weight: 700;
        line-height: 19px;
        padding-bottom: 20px;
    }

    @media only screen and (max-width: 768px) {

        table,
        thead,
        tbody {
            padding: 5px !important;
            width: 100% !important;
            min-width: 100% !important;
            padding: 0 !important;
        }

        tr,
        td,
        th {
            font-size: 14px !important;
            line-height: 20px !important;
        }

        .update-profile-button {
            font-size: 10px !important;
            width: 115px !important;
            max-width: none !important;
            left: 225px !important;
        }
    }
}