@import '../../styles/variables.scss';

//DIALOG start
.dialog-bg {
  position: fixed;
  z-index: 99990;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6.5rem 3rem;
  .dialog-container {
    position: relative;
    max-width: $container-width;
    width: 100%;
    max-height: 100%;
    background-color: $white;
    .auto-scroll {
      height: 100%;
      overflow-y: auto;
      padding: 8rem 3rem 6rem 3rem;
    }
    .close-btn {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      padding: 1.5rem;
      font-size: 1.4rem;
    }
  }
  .dialog-content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    .sub-title {
      margin-bottom: 3rem;
    }
    p {
      max-width: 85rem;
    }
    .btn {
      width: 100%;
      max-width: 26rem;
      margin-top: 25vh;
    }
    .logout-btn {
      width: 26rem;
      margin-top: 25vh;
    }
  }
  &.alert-box {
    .dialog-container {
      max-width: 85rem;
      .close-btn {
        display: none !important;
      }
      .dialog-content-box .btn {
        margin-top: 6rem;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .dialog-bg .dialog-content-box .sub-title {
    font-size: 2.4rem;
  }
  .dialog-bg .dialog-container {
    overflow: auto;
  }
  .dialog-bg .dialog-container .auto-scroll {
    padding: 4rem 2rem;
  }
  .dialog-bg .dialog-content-box .btn {
    margin-top: 4rem;
  }
  .dialog-bg .dialog-container.has-close-btn {
    padding-top: 2rem;
  }
}
//DIALOG end

//HEADER start
.header {
  position: absolute;
  background-color: transparent;
  width: 100%;
  .container {
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    width: 11.6rem;
    height: auto;
  }
  .account-btn {
    background-color: $white;
    -webkit-appearance: none;

    i {
      font-size: 4rem;
    }
  }
  .btn {
    min-height: 4.4rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .function-box {
    display: flex;
    align-items: center;
  }
  .language-box {
    display: flex;
    margin-right: 1.3rem;
    .language-btn {
      margin-right: 1.2rem;
      position: relative;
      display: flex;
      align-items: center;
      &::after {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $grey-600;
        margin-left: 1.2rem;
      }
      .active {
        color: $primary;
      }
    }
    .language-btn:last-child::after {
      display: none;
    }
  }
}
.footer {
  background-color: transparent;
  width: 100%;
  padding-bottom: 3rem;

  &.grey-bg {
    background-color: $bg;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  p {
    color: $grey-600;
    margin-bottom: 2rem;
  }
  a {
    display: inline-flex;
    padding: 3px 1rem;
    text-decoration: underline;
    &:last-child {
      padding-right: 0;
      border-right: none;
    }
    &:first-child {
      padding-left: 0;
    }
  }
}
@media only screen and (max-width: 767px) {
  .header {
    .logo {
      width: 6rem;
    }
    .btn {
      min-height: 3.6rem;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .account-btn i {
      font-size: 2.4rem;
    }
  }
  .footer {
    .container {
      flex-direction: column;
      font-size: 1.4rem;
      align-items: flex-start;
    }
    p {
      margin-bottom: 2rem;
    }
    a {
      display: flex;
      border-right: none;
      padding: 3px 0;
    }
  }
}
//HEADER end

//PAGE HEADER start
.page-header {
  position: relative;
  margin-top: 8rem;
  padding: 0 6rem;
  text-align: center;
  .back-btn {
    position: absolute;
    display: flex;
    width: 4rem;
    height: 4rem;
    top: 0;
    left: 0;
    i {
      font-size: 3rem;
    }
  }
  .page-title {
    margin-bottom: 3rem;
  }
  p {
    max-width: 85rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 767px) {
  .page-header {
    margin-top: -2rem;
    .back-btn i {
      font-size: 2.4rem;
    }
    .page-title {
      margin-bottom: 2rem;
    }
    .f-s-18 {
      font-size: 1.6rem;
    }
  }
}

//PAGE HEADER end

//REMINDER BOX start
.remainder-box {
  padding: 3rem 6rem 3rem 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $bg;
  margin-top: 3rem;
  .title-box {
    display: flex;
    align-items: center;
    margin-right: 2rem;
    i {
      margin-right: 1rem;
    }
  }
  &.warn {
    background-color: $red-bg;
    .title-box i {
      color: $red-font;
    }
  }
}
@media only screen and (max-width: 767px) {
  .remainder-box {
    &.warn {
      margin-top: 2rem;
    }
    flex-direction: column;
    padding: 1.5rem 1rem;
    align-items: flex-start;
    .title-box {
      margin-bottom: 5px;
      .f-s-20 {
        font-size: 1.4rem;
      }
      .icon-reminder {
        font-size: 2rem;
      }
    }

    p {
      font-size: 1.6rem;
      padding-left: 3rem;
    }
  }
}
//REMINDER BOX end

.bit-image-box {
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-color: $bg;
  background-image: url('https://storage.googleapis.com/gd-wagtail-prod-assets/original_images/Hannah_Buckman_Split_Hero_2400x3000.png');
}

//Tab
.tab-box {
  display: flex;
  border-bottom: 1px solid $border;
  margin-bottom: 4rem;
  button {
    font-size: 2rem;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 1.5rem;
    border-bottom: 4px solid $white;
    margin-right: 3rem;
    &.active {
      border-bottom-color: $primary;
      color: $primary;
    }
  }
}
@media only screen and (max-width: 767px) {
  .tab-box {
    justify-content: center;
    border-bottom: none;
    button {
      font-size: 1.4rem;
      margin-right: 0;
      padding: 5px 1rem;
    }
    button + button {
      margin-left: 2rem;
    }
  }
}

//no data
.no-data-box {
  padding-top: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  p{
    color:#003448
  }
  .icon-box {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    margin-bottom: 1rem;
    img {
      width: 12.4rem;
      height: auto;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid $primary;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.language-box {
  display: flex;
  margin-right: 1.3rem;
  .language-btn {
    margin-right: 1.2rem;
    position: relative;
    display: flex;
    align-items: center;
    &::after {
      content: '';
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $grey-600;
      margin-left: 1.2rem;
    }
    .active {
      color: $primary;
    }
  }
  .language-btn:last-child::after {
    display: none;
  }
}
